import { Form, message } from "antd";
import { apiCreate } from "../../api/crud";
import { CoreForm } from "../../components/CoreForm";
import { useNavigate } from "react-router-dom";
import PlanForm from "./PlanForm";

const PlanCreate = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 });
  const nav = useNavigate();
  const [form] = Form.useForm();
  const startValue: Api.Response.Plan = {
    is_active: false,
    name: "",
    max_length_video: 0,
    languages: "en",
    has_logo: false,
    save_origin_voice: false,
    queue: 0,
    notification: false,
    voice_clone: false,
    lipsync: false,
    from_youtube: false,
    subtitle_download: false,
    subtitle_on_video: false,
    subtitle_edit: false,
    price_per_minute: "0",
  };
  const handleSave = async (values: Api.Response.Plan) => {
    const result = await apiCreate<Api.Response.Plan>("plan", values);
    if (result.isError) {
      messageApi.error("Произошла ошибка при создании тарифа");
    } else {
      nav(`/admin/plan/${result.data.id}`);
    }
  };

  return (
    <CoreForm title="Тарифы">
      {contextHolder}
      <PlanForm form={form} startValue={startValue} handleSave={handleSave} />
    </CoreForm>
  );
};

export default PlanCreate;
