import { CoreSection } from "./Core"
import { Typography } from 'antd'
import type { CollapseProps } from 'antd';
import { Collapse } from 'antd';


const FaqSection = () => {

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: 'В каком формате можно загружать аудио/видео?',
            children: <Typography.Paragraph>
                Поддержка различных языков на входе и выходе. Разрешения видео: от 360x360 до 4096x4096. Длительность видео: от 30 секунд до 1 часа. Оптимизация под видео с одним говорящим, желательно избегать фоновой музыки и шума.
                Для перевода аудио лучше всего подходят файлы WAV формата с частотой дискретизации 44Кгц, файлы MP3 тоже поддерживаются. Что касается видео, возможна загрузка видео с Youtube или с компьютера в формате mp4.
            </Typography.Paragraph>
        },
        {
            key: '2',
            label: 'Как добиться максимально качественной озвучки?',
            children: <Typography.Paragraph>
                Для максимально точного перевода и озвучки необходимо чтобы качество голоса в оригинальном видео было максимально четким, лучше всего студийным. От этого зависит, как нейронные сети смогут распознать голос в ваших фрагментах материала и клонировать его.
            </Typography.Paragraph>
        },
        {
            key: '3',
            label: 'Как долго по времени делается перевод?',
            children: <Typography.Paragraph>
                Задача по переводу и синтезу речи выполняется исключительно на графических ускорителях GPU и зависит от длины видео и выбранных вами опций перед началом перевода. Мы отображаем примерное время перевода с учетом текущей нагрузки и спроса на услугу.
            </Typography.Paragraph>
        },
        {
            key: '4',
            label: 'Есть ли поддержка китайского языка?',
            children: <Typography.Paragraph>
                Да, поддержка перевода видео с русского или же с других языков на китайский есть.
            </Typography.Paragraph>
        },
        {
            key: '5',
            label: 'Можно ли отдельно скачать титры в текстовом формате?',
            children: <Typography.Paragraph>
                Да, титры в формате srt доступны к скачиванию при завершении перевода видео на странице загрузки видео.
            </Typography.Paragraph>
        },
        {
            key: '6',
            label: 'Поддерживает ли перевод несколько спикеров в одном ролике?',
            children: <Typography.Paragraph>
                Да, но синтез речи поддерживается максимум для 2 спикеров, если в вашем ролике 3 и более спикеров, то в переводе разбивка произойдет в любом случае только на двоих.
            </Typography.Paragraph>
        },
        {
            key: '7',
            label: 'Могут ли случаться ошибки в переводе речи?',
            children: <Typography.Paragraph>
                Ошибки могут возникать если речь разных спикеров накладывается друг на друга, в звуковом дорожке присутствуют посторонние звуки (музыка, шум ветра и др.), речь неразборчивая или слишком тихая.
            </Typography.Paragraph>
        },
        {
            key: '8',
            label: 'Что такое lip sync?',
            children: <Typography.Paragraph>
                Lip sync - это синхронизация движения губ диктора в видео с озвучкой на другом языке. Эта функция делает автоматический перевод еще более реалистичным, создавая эффект полного погружения.
            </Typography.Paragraph>
        },
        {
            key: '9',
            label: 'Что такое клонирование речи?',
            children: <Typography.Paragraph>
                Клонирование речи - это технология на основе искусственного интеллекта, позволяющая сгенерировать голос диктора для перевода, который звучит максимально похоже на оригинал. Это помогает сохранить узнаваемость и особенности речи говорящего.
            </Typography.Paragraph>
        },
        {
            key: '10',
            label: 'Что значит наложение субтитров?',
            children: <Typography.Paragraph>
                Наложение субтитров - это функция, которая позволяет "прожечь" сгенерированные переведенные субтитры поверх оригинального видео. Это удобно при публикации ролика на YouTube, чтобы зрители могли включить субтитры на нужном языке.
            </Typography.Paragraph>
        },
        {
            key: '11',
            label: 'Что такое редактор субтитров?',
            children: <Typography.Paragraph>
                Редактор субтитров - это инструмент, который позволяет откорректировать автоматически сгенерированные субтитры перед финальным синтезом речи. Это дает дополнительный контроль качества перевода.
            </Typography.Paragraph>
        },
        {
            key: '12',
            label: 'Что означает нотификация об окончании перевода?',
            children: <Typography.Paragraph>
                После завершения процесса перевода видео мы отправляем уведомление на ваш email или telegram аккаунт. Так вы сразу узнаете, что видео готово, и можете приступить к просмотру результата.
            </Typography.Paragraph>
        },
        {
            key: '13',
            label: 'Что означает сохранить оригинальную приглушенную речь?',
            children: <Typography.Paragraph>
                Эта опция позволяет оставить фоновой оригинальную речь диктора в видео, но значительно приглушить ее громкость. Так сохраняется атмосфера исходного видео.
            </Typography.Paragraph>
        },
        {
            key: '14',
            label: 'Что означает сохранить фоновую музыку и звуки?',
            children: <Typography.Paragraph>
                Эта функция вырезает из видео только речь диктора, но оставляет всю фоновую музыку, шумы и прочие звуки. Так в переведенном видео сохраняется полная звуковая атмосфера.
            </Typography.Paragraph>
        },
        {
            key: '15',
            label: 'Для чего нужен промокод?',
            children: <Typography.Paragraph>
                Промокоды предоставляют скидку на оплату услуг перевода видео или дают возможность воспользоваться услугой ограниченное количество раз бесплатно. Введите промокод при оформлении заказа, чтобы получить скидку.
            </Typography.Paragraph>
        },
        {
            key: '16',
            label: 'Как производится оплата?',
            children: <Typography.Paragraph>
                Оплата производится перед началом оказания услуг перевода, после загрузки видео мы рассчитываем итоговую стоимость перевода на основании длины видео и выбранных нами опций. Доступны платежи банковской картой, ЮMoney, WebMoney и другие способы.
            </Typography.Paragraph>
        },
        {
            key: '17',
            label: 'Какие у вас есть тарифы?',
            children: <Typography.Paragraph>
                У нас доступны 3 основных тарифных плана - Базовый, Профессиональный и Премиум с расширенными возможностями перевода. Подробнее ознакомиться и выбрать подходящий тариф можно на странице с прайсом.
            </Typography.Paragraph>
        },
        {
            key: '18',
            label: 'Нужен ли мне аккаунт для перевода видео?',
            children: <Typography.Paragraph>
                Да, для разового заказа перевода регистрировать аккаунт обязательно. Рекомендуем зарегистрироваться - так вам будет удобнее отслеживать все заказы в личном кабинете.
            </Typography.Paragraph>
        },
        {
            key: '19',
            label: 'Как с вами связаться?',
            children: <Typography.Paragraph>
                Связаться с нами можно по email info@voicecover.ru или по телефону +7 917 946 44 50. Также мы отвечаем на вопросы в наших соцсетях - ВКонтакт, Telegram. Наши менеджеры всегда готовы оперативно проконсультировать вас!
            </Typography.Paragraph>
        },


    ]

    return <CoreSection>
        <Typography.Title level={1}>FAQ: вопросы и ответы:</Typography.Title>
        <Collapse items={items} />
    </CoreSection>
}
export default FaqSection