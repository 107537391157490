import { apiCreate } from '../../api/crud';
import { Flex, Form, message, Button } from "antd";
import {  useNavigate } from "react-router-dom";
import { FormatFormInput } from "../../components/FormatFormInput";
import { CoreForm } from "../../components/CoreForm";

const UserFormCreate = () => {
    const [messageApi, contextHolder] = message.useMessage({ duration: 5 });
    const nav = useNavigate();
    const [form] = Form.useForm()


    const handleSave = async (values: any) => {
        const result = await apiCreate<Api.Response.User>('user', values)
        if (result.isError) {
            messageApi.error('Произошла ошибка при создании пользователя')
        } else {
            nav(`/admin/user/${result.data.id}`)
        }
    }

    const generatePassword = () => {
        const passwordLength = 12
        let charset = "";
        let newPassword = "";
        charset += "0123456789";
        charset += "abcdefghijklmnopqrstuvwxyz";
        charset += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        for (let i = 0; i < passwordLength; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return newPassword
    }

    return <>{contextHolder}
        <CoreForm title='Создание пользователя' extra={
            <Flex gap={8}>
                <Button type="primary" onClick={() => form.submit()}>Сохранить</Button>
                <Button type="primary" danger onClick={() => nav(-1)}>Отмена</Button></Flex>
        }>
            <Form form={form} labelCol={{ span: 4 }} initialValues={{ is_active: true, password: generatePassword(), role: 'user' }} onFinish={handleSave}>
                <FormatFormInput label='Активен' name='is_active' typeData="boolean" />
                <FormatFormInput label='Электронная почта' name='email' typeData="text" />
                <FormatFormInput label='Пароль' name='password' typeData="password" />
                <FormatFormInput label='Роль' name='role' typeData="role" />
            </Form>
        </CoreForm>
    </>

}

export default UserFormCreate
