import { useEffect } from 'react'

const DynamicStyleLoader = ({ url }: { url: string }) => {
  useEffect(() => {
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.href = url

    document.head.appendChild(link)

    return () => {
      document.head.removeChild(link)
    }
  }, [url])

  return null
}

export default DynamicStyleLoader
