import React, { MouseEvent } from 'react'

import './circle-button.scss'

type Props = {
  text?: string
  className?: string
  iconClassName: string
  disabled?: boolean
  onClick: (me: MouseEvent) => void
}

export default class CircleBtn extends React.Component<Props> {
  render() {
    return (
      <>
        <div className={'d-flex align-items-center gap-1 m-1 ' + this.props.className}>
          <button
            className={'btn circle-btn add-caption-btn m-0 ' + (this.props.disabled ? 'disabled' : '')}
            onClick={this.props.onClick}
          >
            <span className={this.props.iconClassName}></span>
            {this.props.text ? (
              <span className='m-0 px-1 font-weight-bold btn-text' style={{ fontSize: '14px' }}>
                {' '}
                {this.props.text}{' '}
              </span>
            ) : (
              ''
            )}
          </button>
        </div>
      </>
    )
  }
}
