import { Flex, Card, Row, Col } from 'antd'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const Auth = () => {
  const [title, setTitle] = useState('')
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname.split('/').slice(-1)[0]) {
      case 'login': {
        setTitle('Вход в систему')
        break
      }
      case 'reg': {
        setTitle('Регистрация')
        break
      }
      case 'forget': {
        setTitle('Восстановление пароля')
        break
      }
      case 'activate': {
        setTitle('Активация учетной записи')
        break
      }
    }
  }, [location])
  return (
    <Row>
      <Col sm={0} md={12} lg={16} className='registration-image'></Col>
      <Col sm={24} md={12} lg={8}>
        <Flex align='center' justify='end' style={{ height: '100svh' }}>
          <Card
            title={title ? title : 'Voicecover'}
            style={{ display: 'grid', gridTemplateRows: '52px 1fr', height: '100%', width: '500px', borderRadius: '0' }}
            bordered={false}
            bodyStyle={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Outlet />
          </Card>
        </Flex>
      </Col>
    </Row>
  )
}

export default Auth
