import type { DescriptionsProps } from 'antd';
import { CoreForm } from "../../components/CoreForm";
import { Descriptions, Flex, Button, Tag } from "antd";
import { FormatField } from "../../components/FormatField";
import { act } from "react-dom/test-utils";
import { apiGetOne } from '../../api/crud';
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

const PromoDetail = () => {
    const pageParams = useParams();
    const id = pageParams.id
    const nav = useNavigate()
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState<DescriptionsProps['items']>([])
    const [active, setAcive] = useState(false)
    const [code, setCode] = useState('')
    const url = 'promocode'

    const getData = async () => {
        if (id) {
            const result = await apiGetOne(url, Number.parseInt(id))
            if (result.isError) {
                setLoading(true)
                return;
            }
            setLoading(false)
            return result.data;
        }
    }

    useEffect(() => {
        if (loading) {
            getData().then((data) => {
                setAcive(data?.is_active)
                setCode(data?.code)
                const _items: DescriptionsProps['items'] = [
                    {
                        label: 'Скидка',
                        span: 3,
                        children: <FormatField value={data?.discount} type='text' />
                    },
                    {
                        label: 'Доступен до',
                        span: 3,
                        children: <FormatField value={data?.valid_at} type='date' />
                    },
                    {
                        label: 'Количество',
                        span: 3,
                        children: <FormatField value={data?.valid_count} type='number' />
                    },
                ]
                setItems(_items)
            });

        }
    })
    return <>{items &&
        <CoreForm title={<Flex>
            <FormatField value={active} type="activeTag" />{code}
        </Flex>} extra={
            <Flex justify='end' gap={8}>
                <Button type="link" onClick={() => nav(-1)}>Назад</Button>
                <Button type="primary" onClick={() => nav(`/admin/promo/${id}/edit`)}>Изменить</Button>
            </Flex>}>
            <Descriptions layout="horizontal" items={items} />
        </CoreForm>}
    </>

}
export default PromoDetail