export const PriceOptionText = [
    { key: "has_logo", text: "Наш логотип на видео" },
    { key: "max_length_video", text: "Макс. длина видео, минут:" },
    { key: "languages", text: "Языки перевода:" },
    { key: "save_origin_voice", text: "Сохранить оригинальную приглушенную речь" },
    { key: "queue", text: "Приоритет по очереди:" },
    { key: "save_background_audio", text: "Сохранить фоновую музыку и звуки" },
    { key: "notification", text: "Уведомление об окончании перевода" },
    { key: "voice_clone", text: "Клонирование речи (один спикер)" },
    { key: "subtitle_download", text: "Скачивание субтитров" },
    { key: "from_youtube", text: "Перевод youtube видео по ссылке" },
    { key: "subtitle_on_video", text: "Наложение субтитров" },
    { key: "subtitle_edit", text: "Редактор субтитров" },
    { key: "lipsync", text: "Синхронизация движения губ" },
]