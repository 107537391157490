import { Flex, Form, Select } from "antd";
const field = {
    "": "Все",
    "credit": 'Исходящая',
    "debit": 'Входящая',
}
const FilterFormTransType = () => {
  return (
    <Flex vertical>
        <Form.Item name="type_transaction__in">
          <Select
            style={{ marginLeft: "5px" }}
            placeholder="Тип транзакции"
            size="small"
            options={Object.entries(field).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
    </Flex>
  );
};

export default FilterFormTransType;
