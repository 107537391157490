import { useState, useEffect } from 'react'
import {
  CheckCircleFilled,
  CloseCircleFilled,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  ClockCircleOutlined,
  EllipsisOutlined
} from '@ant-design/icons'
import { Tag } from 'antd'
import dayjs from 'dayjs'

const FormatField = ({ value, type }: { value: any; type: string }) => {
  const [formatValue, setFormatValue] = useState(value)
  useEffect(() => {
    const _value = value ? value : '-'
    switch (type) {
      case 'datetime':
        setFormatValue(value ? dayjs(value).format('DD.MM.YYYY HH:mm:ss') : '-')
        break
      case 'date':
        setFormatValue(value ? dayjs(value).format('DD.MM.YYYY') : '-')
        break
      case 'time':
        setFormatValue(value ? dayjs(value).format('HH:mm:ss') : '-')
        break
      case 'text':
        setFormatValue(_value)
        break
      case 'money':
        setFormatValue(
          Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB'
          }).format(parseFloat(value))
        )
        break
      case 'number':
        setFormatValue(Intl.NumberFormat('ru-RU').format(parseFloat(value)))
        break
      case 'email':
        setFormatValue(<a href={`mailto:${_value}`}>{_value}</a>)
        break
      case 'telegram':
        setFormatValue(<a href={`https://t.me/${_value}`}>{_value}</a>)
        break
      case 'phone':
        setFormatValue(<a href={`tel:${_value}`}>{_value}</a>)
        break
      case 'bool':
        setFormatValue(
          value ? (
            <CheckCircleFilled style={{ color: 'green', widows: '24px' }} />
          ) : (
            <CloseCircleFilled style={{ color: 'red', widows: '24px' }} />
          )
        )
        break
      case 'role':
        setFormatValue(
          value === 'admin' ? (
            <Tag color='volcano'>{value}</Tag>
          ) : value === 'manager' ? (
            <Tag color='green'>{value}</Tag>
          ) : (
            <Tag color='geekblue'>{value}</Tag>
          )
        )
        break
      case 'typeTransaction':
        setFormatValue(
          value === 'debit' ? (
            <Tag color='green' icon={<ArrowRightOutlined />}>
              Входящая
            </Tag>
          ) : (
            <Tag color='red' icon={<ArrowLeftOutlined />}>
              Исходящая
            </Tag>
          )
        )
        break
      case 'activeTag':
        setFormatValue(value ? <Tag color='green'>Активен</Tag> : <Tag color='red'>Неактивен</Tag>)
        break
      case 'status':
        let statusIcon
        let statusColor

        switch (value) {
          case 'Ожидание пользователя':
          case 'Видео загружено':
          case 'Обработка субтитров':
          case 'Обработка видео':
          case 'Формирование голоса':
            statusIcon = <ClockCircleOutlined />
            statusColor = 'yellow'
            break
          case 'Завершено':
            statusIcon = <CheckCircleFilled />
            statusColor = 'green'
            break
          case 'Ошибка':
            statusIcon = <CloseCircleFilled />
            statusColor = 'red'
            break
          default:
            statusIcon = <EllipsisOutlined />
            statusColor = 'gray'
        }

        setFormatValue(
          <Tag color={statusColor} icon={statusIcon}>
            {value}
          </Tag>
        )
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return <span>{formatValue}</span>
}

export default FormatField
