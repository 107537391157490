import React from "react";
import { Flex, Form, Input, Select, Space, Typography, DatePicker, Radio } from "antd";

const FilterFormPromo = ({ form }: { form?: any }) => {
  const percent = {
    0: "0%",
    10: "10%",
    20: "20%",
    30: "30%",
    40: "40%",
    50: "50%",
    60: "60%",
    70: "70%",
    80: "80%",
    90: "90%",
    100: "100%",
  };

  const marginBottomStyle = {
    marginBottom: '0px', 
  };
  return (
    <Flex vertical style={{ marginBottom: "20px" }}>
      <Typography.Paragraph style={marginBottomStyle}>Наименование</Typography.Paragraph>
      <Form.Item name="promocode__code__ilike">
        <Input placeholder="Наименование" />
      </Form.Item>

      <Typography.Paragraph style={marginBottomStyle}>Размер скидки</Typography.Paragraph>
      <Space>
        <Form.Item label="От" name="promocode__discount__gte">
          <Select
            style={{ marginLeft: "5px", width: "70px" }}
            placeholder="Процент скидки"
            size="small"
            options={Object.entries(percent).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
        <Form.Item label="До" name="promocode__discount__lte">
          <Select
            style={{ marginLeft: "5px", width: "70px" }}
            placeholder="Процент скидки"
            size="small"
            options={Object.entries(percent).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
      </Space>
      <Typography.Paragraph style={marginBottomStyle}>Количество использований</Typography.Paragraph>
      <Space>
        <Form.Item name="promocode__valid_count__gte" label="От">
          <Input
            placeholder="От"
            style={{ marginLeft: "5px", width: "70px" }}
          />
        </Form.Item>
        <Form.Item name="promocode__valid_count__lte" label="До">
          <Input
            placeholder="До"
            style={{ marginLeft: "5px", width: "70px" }}
          />
        </Form.Item>
      </Space>
      <Typography.Paragraph style={marginBottomStyle}>Срок действия</Typography.Paragraph>
      <Space>
        <Form.Item label="От" name="promocode__valid_at__gte" valuePropName="date">
          <DatePicker format="YYYY/MM/DD" onChange={(data) => form.setFieldValue('promocode__valid_at__gte',  data?.unix())}/>
        </Form.Item>
        <Form.Item label="До" name="promocode__valid_at__lte" valuePropName="date">
          <DatePicker format="YYYY/MM/DD" onChange={(data) => form.setFieldValue('promocode__valid_at__lte',  data?.unix())}/>
        </Form.Item>
      </Space>
      <Typography.Paragraph style={marginBottomStyle}>Активность промокода</Typography.Paragraph>
      <Form.Item name="promocode__is_active">
          <Radio.Group>
            <Radio value=""> Все записи </Radio>
            <Radio value="true"> Активные </Radio>
            <Radio value="false"> Не активные </Radio>
          </Radio.Group>
        </Form.Item>
    </Flex>
  );
};

export default FilterFormPromo;
