export const
    MAX_HISTORY = 50,
    SHOOT_TIME_MAJOR = 5,
    SHOOT_TIME_MINOR = 0.5,

    SHOOT_SUBTITLE_TIME_MAJOR = 0.5,
    SHOOT_SUBTITLE_TIME_MINOR = 0.1,

    ADD_CAPTION_TIME = 0.3,

    TIMELINE_CURSOR_OFFSET = 0, // per seconds

    SHOOT_ZOOM = 10,
    DEFAULT_SCALE = 30,
    MAX_SCALE = 100,

    MAX_CANVAS_SIZE = 32_000 // it's actually 32,767 but I prefer not to go around the limit
