import { Button, Form, Input, Flex, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { apiResetPassword } from '../../../api/auth'
import { HeaderLogo } from '../../../components/HeaderLogo'
const Forget = () => {
  const [form] = Form.useForm()
  const nav = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  }
  const handleResetPassword = async ({ email }: { email: string }) => {
    const result = await apiResetPassword(email)

    if (result.isError) {
      if (result.data?.detail === 'User not found') {
        messageApi.warning('Пользователь с такой электронной почтой не найден')
      } else {
        messageApi.error('Произошла ошибка, попробуйте повторить регистрацию')
      }
    } else {
      messageApi.success('Сброс пароля произведен успешно. На вашу электронную почту высланое письмо с новым паролем')
    }
  }
  return (
    <>
      {contextHolder}
      <HeaderLogo size='big' />
      <Form {...layout} form={form} onFinish={handleResetPassword} size='large'>
        <Form.Item
          label='Электронная почта'
          name='email'
          rules={[{ required: true }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Flex align='center' vertical gap={4}>
          <Button htmlType='submit' type='primary' style={{ width: '100%' }}>
            Отправить новый пароль
          </Button>
          <Button htmlType='button' type='link' onClick={() => nav('/auth/login')}>
            Есть логин и пароль
          </Button>
        </Flex>
      </Form>
    </>
  )
}

export default Forget
