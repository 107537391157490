import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { apiActivate } from '../../../api/auth'
import { Button, Flex } from 'antd'
import { HeaderLogo } from '../../../components/HeaderLogo'
const Activate = () => {
  const location = useLocation()
  const nav = useNavigate()
  const activateCode = new URLSearchParams(location.search).get('activate_code')
  const [activate, setActivate] = useState(false)

  useEffect(() => {
    const checkActivateCode = async (activate_code: string) => {
      const result = await apiActivate(activate_code)
      setActivate(!result.isError)
    }
    if (activateCode) {
      checkActivateCode(activateCode)
    }
  }, [activateCode])

  return (
    <Flex
      justify='center'
      align='baseline'
      style={{ flexDirection: 'column', textAlign: 'center', width: '100%', display: 'grid' }}
    >
      {activate && <div>Активация прошла успешно</div>}
      {!activate && <div>Ошибка активации</div>}
      <Button
        type='primary'
        htmlType='button'
        onClick={() => nav('/auth/login')}
        style={{ marginTop: '20px', width: '100%' }}
      >
        Войти
      </Button>
    </Flex>
  )
}

export default Activate
