import React from 'react'
import { Card, theme } from 'antd'
import PriceOption from './PriceOption'
import { PriceOptionText } from './PriceOptionText'
const { useToken } = theme

const PriceCard = ({ id, headColor }: { id: number; headColor: string }) => {
  const { token } = useToken()
  const [loading, setLoading] = React.useState(false)
  const [plan, setPlan] = React.useState<Api.Response.Plan>()

  const getData = async () => {
    if (id) {
      const data = await fetch(`${process.env.REACT_APP_API_URL}plan/${id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      if (!data.ok) {
        console.error('Ошибка получения тарифа')
        setLoading(true)
        return
      }
      const jsonData = await data.json()
      setLoading(false)
      return { isError: false, data: jsonData }
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const data = await getData()
      if (data) {
        setPlan({
          ...(data.data || {}),
          save_origin_voice: true
        })
      }
    }

    fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Card
      title={plan?.name}
      headStyle={{
        backgroundColor: headColor,
        textAlign: 'center',
        color: token.colorWhite
      }}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
      style={{ height: '100%' }}
    >
      <div
        style={{
          fontSize: '1.5rem',
          fontWeight: 500,
          color: token.colorSuccess,
          width: '100%',
          textAlign: 'center',
          marginBottom: '12px'
        }}
      >
        {plan?.price_per_minute} ₽/мин
      </div>
      {PriceOptionText.map(option => (
        <PriceOption key={option.key} label={option.text} value={plan?.[option.key as keyof typeof plan]} />
      ))}
    </Card>
  )
}
export default PriceCard
