import { Layout, Menu, Typography, theme, Flex, Divider } from 'antd'
import { HeaderLogo } from '../../components/HeaderLogo'
import { CasesSection, AboutSection, PossibilitiesSection, PricesSection, ContactSection, Faq } from './sections'
import { useRef, useState, useEffect } from 'react'
import { MenuInfo } from 'rc-menu/lib/interface'
import { MenuOutlined } from '@ant-design/icons'
import LoginLogout from '../../components/LoginLogout/LoginLogout'
import { Link } from 'react-router-dom'
import { useUtmParams } from '../../hooks/useUtmParams'
const { Header, Content, Footer } = Layout

const { useToken } = theme

const Main = () => {
  const { token } = useToken()
  const [activeSection, setActiveSection] = useState<string>('1')
  const [planList, setPlanList] = useState<Api.Response.Plans[]>()
  const pricesRef = useRef<HTMLDivElement | null>(null)
  const aboutRef = useRef<HTMLDivElement | null>(null)
  const possibilitiesRef = useRef<HTMLDivElement | null>(null)
  const casesRef = useRef<HTMLDivElement | null>(null)
  const contactRef = useRef<HTMLDivElement | null>(null)
  const faqRef = useRef<HTMLDivElement | null>(null)

  //Отслеживание UTM параметров
  useUtmParams()

  const getData = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}plan/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    if (!data.ok) {
      console.error('Ошибка получения тарифов')
      return
    }
    const jsonData = await data.json()
    return { isError: false, data: jsonData }
  }

  useEffect(() => {
    getData().then((data: any) => data && setPlanList(data.data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const items = [
    {
      key: 1,
      label: 'О проекте'
    },
    {
      key: 2,
      label: 'Возможности'
    },
    {
      key: 3,
      label: 'Кейсы'
    },
    {
      key: 4,
      label: 'Цены'
    },
    {
      key: 5,
      label: 'FAQ'
    },
    {
      key: 6,
      label: 'Связь с нами'
    }
  ]

  useEffect(() => {
    switch (activeSection) {
      case '1': {
        aboutRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      }
      case '2': {
        possibilitiesRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      }
      case '3': {
        casesRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      }
      case '4': {
        pricesRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      }
      case '5': {
        faqRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      }
      case '6': {
        contactRef.current?.scrollIntoView({ behavior: 'smooth' })
        break
      }
    }
  }, [activeSection])

  const handleMenuClick = (data: MenuInfo) => {
    setActiveSection(data.key)
  }
  return (
    <Layout style={{ paddingLeft: '10%', paddingRight: '10%' }}>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '6px',
          backgroundColor: token.colorBgBase,
          borderRadius: token.borderRadiusLG,
          padding: '0 20px'
        }}
      >
        <HeaderLogo />
        <Menu
          mode='horizontal'
          defaultSelectedKeys={[activeSection]}
          items={items}
          onClick={data => handleMenuClick(data)}
          overflowedIndicator={<MenuOutlined />}
          style={{ minWidth: 0, flex: 1, justifyContent: 'center' }}
        />
        <LoginLogout />
      </Header>
      <Content>
        <div ref={aboutRef} style={{ minHeight: '100vh' }}>
          <AboutSection />
        </div>
        <div ref={possibilitiesRef}>
          <PossibilitiesSection />
        </div>
        <div ref={casesRef}>
          <CasesSection />
        </div>
        <div ref={pricesRef}>
          <PricesSection data={planList} />
        </div>
        <div ref={faqRef}>
          <Faq />
        </div>
        <div ref={contactRef}>
          <ContactSection />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Flex vertical gap={8}>
          <Typography.Paragraph>© 2024 ООО "ИНТЕЛЛЕКТ ГРУПП"</Typography.Paragraph>
          <Flex gap={8} justify='center'>
            <Link to='/privacy'>Политика в отношении обработки персональных данных</Link>
            <Divider type='vertical' />
            <Link to='/rules'>Правила использования</Link>
          </Flex>
        </Flex>
      </Footer>
    </Layout>
  )
}

export default Main
