import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const UTM_SOURCE_KEY = 'utm_source'

export const useUtmParams = () => {
  const location = useLocation()
  const [utmSource, setUtmSource] = useState<string | null>(() => {
    return localStorage.getItem(UTM_SOURCE_KEY)
  })

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const utmParam = searchParams.get(UTM_SOURCE_KEY)

    if (utmParam) {
      localStorage.setItem(UTM_SOURCE_KEY, utmParam)
      setUtmSource(utmParam)
      console.log('UTM Source:', utmParam)
    } else if (!utmSource) {
      const storedUtmSource = localStorage.getItem(UTM_SOURCE_KEY)
      if (storedUtmSource) {
        setUtmSource(storedUtmSource)
        console.log('Stored UTM Source:', storedUtmSource)
      }
    }
  }, [location.search])

  return utmSource
}
