import { YoutubeOutlined } from '@ant-design/icons'
import React from 'react'
import { Input, Typography } from 'antd'

import './InputUpload.scss'

const InputUpload = () => {
  const { Search } = Input
  const { Text } = Typography
  const [uploadLink, setUploadLink] = React.useState('')
  const [error, setError] = React.useState(false)

  const onSearch = (value: string) => {
    if (value.includes('youtube.com/watch?v=')) {
      setError(false)
      return
    } else {
      setError(true)
    }
  }
  return (
    <div className='input-upload'>
      <span>
        <p className='ant-upload-drag-icon'>
          <YoutubeOutlined />
        </p>
        <p className='ant-upload-text'>Вставьте ссылку на видео из YouTube</p>

        <Search
          placeholder='Видео из YouTube'
          loading={false}
          enterButton='Загрузить'
          size='large'
          style={{ maxWidth: '440px' }}
          value={uploadLink}
          onChange={e => setUploadLink(e.target.value)}
          onSearch={onSearch}
        />
        <p style={{ margin: 0, padding: 0 }}>{error && <Text type='danger'>Не верный формат ссылки!</Text>}</p>

        <p className='ant-upload-hint'>
          Формат ссылки: https://www.youtube.com/watch?v=dQw4w9WgXcQ <br /> Максимальная длина: от 5 секунд до 60 минут{' '}
          <br /> Размер: от 1мб до 1гб.
        </p>
      </span>
    </div>
  )
}

export default InputUpload
