import { CoreSection } from "./Core"
import { Row, Col, Typography, Flex } from 'antd'
import logoBloggers from './../../../assets/bloggers.png'
import logoAuthors from './../../../assets/authors.png'
import logoNews from './../../../assets/news.png'

const CasesSection = () => {
    return <CoreSection>
        <Typography.Title level={1}>Кейсы использования</Typography.Title>
        <Row align='middle' gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 },{ xs: 8, sm: 16, md: 24, lg: 32 }]}>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 8}} xl={{span: 8}}>
                <Flex vertical align="center">
                    <img src={logoBloggers} alt='' width='128px' />
                    <Typography.Title level={5}>
                        Блогерам
                    </Typography.Title>
                    <Typography.Paragraph>
                        Откройте для себя зарубежную аудиторию, верните монетизацию вашему контенту.
                    </Typography.Paragraph>
                </Flex>

            </Col>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 8}} xl={{span: 8}}>
                <Flex vertical align="center">
                    <img src={logoAuthors} alt='' width='128px' />
                    <Typography.Title level={5}>
                        Авторам курсов
                    </Typography.Title>
                    <Typography.Paragraph>
                        Создавайте обучающий контент на русском языке и масштабируйте на зарубежную аудиторию.
                    </Typography.Paragraph>
                </Flex>
            </Col>
            <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}} lg={{span: 8}} xl={{span: 8}}>
                <Flex vertical align="center">
                    <img src={logoNews} alt='' width='128px' />
                    <Typography.Title level={5}>
                        Новостным агенствам
                    </Typography.Title>
                    <Typography.Paragraph>
                        Доносите новости зарубежной аудитории без языкового барьера.
                    </Typography.Paragraph>
                </Flex>
            </Col>
        </Row>
    </CoreSection>
}

export default CasesSection