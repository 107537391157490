import React from "react";
import { Flex, Form, Input, DatePicker } from "antd";
import dayjs from 'dayjs';

import type { Dayjs } from 'dayjs';
import type { TimeRangePickerProps } from 'antd';

const { RangePicker } = DatePicker;

const rangePresets: TimeRangePickerProps['presets'] = [
    { label: 'Последние 7 дней', value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: 'Последние 14 дней', value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: 'Последние 30 дней', value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: 'Последние 90 дней', value: [dayjs().add(-90, 'd'), dayjs()] },
];

const FilterFormDate = ({ form }: { form?: any }) => {
  const onDateChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      form.setFieldValue('created_at__gte',  dates[0]?.unix())
      form.setFieldValue('created_at__lte',  dates[1]?.unix())
    } else {
      form.setFieldValue('created_at__gte',  null)
      form.setFieldValue('created_at__lte',  null)
    }

  };
  return (
    <Flex vertical style={{ marginBottom: "20px" }}>
      <Form.Item>
        <RangePicker presets={rangePresets} onChange={onDateChange}  format="YYYY/MM/DD"/>
      </Form.Item>
      <Form.Item name="created_at__gte" hidden>
        <Input placeholder="От"/>
      </Form.Item>
      <Form.Item name="created_at__lte" hidden>
        <Input placeholder="До"/>
      </Form.Item>
    </Flex>
  );
};

export default FilterFormDate;
