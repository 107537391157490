import { second2timestamp, timestamp2seconds } from "./timestamp"
import { v4 as uuid } from "uuid"

export type Caption = {
    start: number
    end: number
    content: string
    hash: string // for rendering
}

export function captionsCompare(a: Caption, b: Caption): number {
    if (a.start > b.start) return 1
    else if (b.start > a.start) return -1
    else return 0
}

export function areSameCaptions(a: Caption, b: Caption): boolean {
    return (a.start === b.start) && (a.end === b.end) && (a.content === b.content)
}

// save as .srt format
export function export2srt(caps: Caption[]): string {
    return caps.map((c, i) =>
        `${i + 1}\n${second2timestamp(c.start, "complete")} --> ${second2timestamp(c.end, "complete")}\n${c.content}`)
        .join('\n\n')
}

// export function parseSrt(content: string): Caption[] {
//     const matches = Array.from(content.matchAll(
//         /\d+\n([\d,:]{12}) --> ([\d,:]{12})\n([\s\S]+?)(?=\d+\n[\d,:]{12}|$)/gs))

//     return matches.map(m => ({
//         start: timestamp2seconds(m[1]),
//         end: timestamp2seconds(m[2]),
//         content: m[3].trim(),
//         hash: uuid()
//     }))
// }
// export function parseSrt(content: string): Caption[] {
//   const matches = Array.from(content.matchAll(
//     /\d+\n([\d,:]{12}) --> ([\d,:]{12})\n(.+?)(?=\d+\n[\d,:]{12}|$)/gs))

//   return matches.map(m => ({
//     start: timestamp2seconds(m[1]),
//     end: timestamp2seconds(m[2]),
//     content: m[3].trim(),
//     hash: uuid()
//   }))
// }
export function parseSrt(content: string): Caption[] {
    const regex = /\d+\n([\d,:]{12}) --> ([\d,:]{12})\n([\s\S]+?)(?=\d+\n[\d,:]{12}|$)/g;
    const captions: Caption[] = [];
    let match;

    while ((match = regex.exec(content)) !== null) {
        const [, startTime, endTime, captionContent] = match;
        captions.push({
            start: timestamp2seconds(startTime),
            end: timestamp2seconds(endTime),
            content: captionContent.trim(),
            hash: uuid()
        });
    }

    return captions;
}

// Преобразовать строку WebVTT в массив объектов Caption
export function parseVtt(vttString: string) {
    const captions = [];

    // Разделить строку WebVTT по символу новой строки
    const lines = vttString.trim().split('\n');

    let start = 0;
    let end = 0;
    let content = '';

    // Пройти по каждой строке
    lines.forEach(line => {
        // Если строка содержит временные метки, начать новый Caption
        if (line.includes('-->')) {
            const times = line.split(' --> ');
            start = timestamp2seconds(times[0]);
            end = timestamp2seconds(times[1]);
        }
        // Если строка не пустая и не содержит временные метки, добавить её к текущему content
        else if (line.trim() !== '') {
            if (content !== '') {
                content += '\n';
            }
            content += line.trim();
        }
        // Если строка пустая и content не пустой, добавить новый Caption
        else if (content !== '') {
            const hash = uuid();
            captions.push({ start, end, content, hash });
            content = '';
        }
    });

    // Добавить последний Caption, если он есть
    if (content !== '') {
        const hash = uuid();
        captions.push({ start, end, content, hash });
    }

    return captions;
}

export function captions2vtt(captions: Caption[]) {
    let vttString = 'WEBVTT\n\n'; // Начало файла VTT

    captions.forEach(caption => {
        if (caption.start === 0 && caption.end === 0) return
        // Преобразовать время начала и конца в строку формата VTT
        const startTime = second2timestamp(caption.start, "complete");
        const endTime = second2timestamp(caption.end, "complete");

        // Добавить временные метки
        vttString += `${startTime} --> ${endTime}\n`;

        // Добавить контент
        vttString += `${caption.content}\n\n`;
    });

    return vttString;
}