// TODO доделать прогресс загрузки
import React from 'react'
import { CoreForm } from '../../components/CoreForm'

import { InboxOutlined, YoutubeOutlined, DesktopOutlined } from '@ant-design/icons'
import type { UploadProps } from 'antd'
import { Col, message, Popover, Radio, Row, Space, Upload } from 'antd'
import { apiUpload } from '../../api/crud'
import VideoSteps from './components/Steps'
import { useNavigate } from 'react-router-dom'
import InstructionUpload from './components/InstructionUpload'
import InputUpload from './components/InputUpload/InputUpload'

const { Dragger } = Upload
const current = 0

const VideoUpload = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const navigate = useNavigate()
  const [progress, setProgress] = React.useState(0)
  const [localUploadMode, setLocalUploadMode] = React.useState(true)
  const handleUploadSuccess = (videoId: string) => {
    navigate('/app/add/' + videoId)
  }
  const upload = async (options: any) => {
    const { onSuccess, onError, file, onProgress } = options

    const fmData = new FormData()
    fmData.append('upload', file)

    try {
      const res = await apiUpload<Api.Response.VideoUpload>('translate/upload', fmData, event => {
        const percent = Math.floor((event.loaded / (event.total || 1)) * 100)
        setProgress(percent)
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000)
        }
        onProgress({ percent: (event.loaded / (event.total || 1)) * 100 })
      })

      if (res.isError) {
        throw new Error('Ошибка загрузки')
      }
      messageApi.success(`${file.name} видео загружено!`)
      onSuccess('Ok')

      handleUploadSuccess(res.data.id.toString())
    } catch (err) {
      console.error('Error: ', err)
      messageApi.error(`${file.name} загрузка не удалась, попробуйте еще раз.`)
      onError({ err })
    }
  }

  const props: UploadProps = {
    name: 'upload',
    multiple: false,
    customRequest: upload,
    accept: '.mp4, .mov',

    onDrop(e) {}
  }

  return (
    <>
      {contextHolder}
      <CoreForm title='Добавить видео'>
        <VideoSteps current={current} />
        {current === 0 && (
          <>
            <Space style={{ padding: '40px 0 0', marginBottom: '-15px', justifyContent: 'center', width: '100%' }}>
              <Radio.Group
                onChange={() => setLocalUploadMode(!localUploadMode)}
                value={localUploadMode}
                style={{ marginBottom: 8 }}
              >
                <Radio.Button value={true}>
                  <DesktopOutlined /> Загрузить с компьютера
                </Radio.Button>
                <Radio.Button value={false} disabled title='Скоро!'>
                  <YoutubeOutlined /> Загрузить по ссылке (скоро!)
                </Radio.Button>
              </Radio.Group>
            </Space>
            {localUploadMode ? (
              <Dragger {...props} style={{ marginTop: '20px' }}>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p className='ant-upload-text'>Нажмите или перетащите видео сюда</p>
                <p className='ant-upload-hint'>
                  Возможно загрузка одного видео за раз формата: .mp4, .mov <br /> Максимальная длина: от 5 секунд до 60
                  минут <br /> Размер: от 1мб до 1гб.
                </p>
              </Dragger>
            ) : (
              <InputUpload />
            )}
          </>
        )}
        <InstructionUpload />
      </CoreForm>
    </>
  )
}
export default VideoUpload
