import VoiceItem from './VoiceItem'
import React, { useEffect, useState } from 'react'
import { Button, Dropdown } from 'antd'
import { MenuProps } from 'antd/lib'
import { voices } from '../../../utils/voices'
interface VoiceListProps {
  onVoiceChange: (voice: string | null) => void
  lang: string
}

const VoiceList: React.FC<VoiceListProps> = ({ onVoiceChange, lang }) => {
  const [selectedVoice, setSelectedVoice] = useState<string | null>(null)
  const [audio, setAudio] = useState<HTMLAudioElement | null>(null)
  const [filteredVoices, setFilteredVoices] = useState(voices)
  const handleVoiceChange = (value: string | null, name: string) => {
    setSelectedVoice(name)
    value && onVoiceChange(value)

    if (audio) {
      audio.pause()
    }
  }

  const playSample = (sample: string) => {
    if (audio) {
      audio.pause()
    }
    const audioInstance = new Audio(sample)
    setAudio(audioInstance)
    audioInstance.play()
  }

  const handlePlayClick = (sample: string, e: React.MouseEvent) => {
    e.stopPropagation()
    playSample(sample)
  }

  useEffect(() => {
    const defaultVoice = voices.find(voice => voice.lang === lang)
    if (defaultVoice) {
      setSelectedVoice(defaultVoice.name)
      onVoiceChange(defaultVoice.value)
    } else {
      setSelectedVoice(null)
      onVoiceChange(null)
    }
    setFilteredVoices(voices.filter(voice => voice.lang === lang))
  }, [lang])

  const items: MenuProps['items'] = filteredVoices?.map((voice, iterator) => ({
    key: iterator,
    label: (
      <VoiceItem
        name={voice.name}
        sample={voice.sample}
        value={voice.value}
        handlePlayClick={handlePlayClick}
        handleVoiceChange={handleVoiceChange}
      />
    )
  }))

  return (
    <Dropdown menu={{ items }} trigger={['click']} overlayStyle={{ maxWidth: '320px' }}>
      <Button style={{ width: '100%', maxWidth: '320px' }}>{selectedVoice || 'Выберите голос'}</Button>
    </Dropdown>
  )
}

export default VoiceList
