import React, { useState, useEffect } from 'react'
import { Button, Flex, Card, Row, Col, Statistic, Radio, message } from 'antd'
import { CoreForm } from '../../components/CoreForm'
import { FormatField } from '../../components/FormatField'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../hooks/useAppDispatch'
import { useAppSelector } from '../../hooks/useAppSelector'
import { fetchSiteStatus } from '../../store/slices/settings'
import { apiGetListWithParams } from '../../api/crud'
import { format } from 'date-fns'
import {
  UserOutlined,
  TranslationOutlined,
  TransactionOutlined,
  WalletOutlined,
  DollarOutlined,
  BarChartOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { helpers } from '../../utils/helpers'

type PeriodType = 'day' | 'week' | 'month' | 'year'

interface StatisticCardProps {
  title: string
  total: number | string
  new: number | string
  icon: React.ReactNode
  onClick: () => void
}

const StatisticCard: React.FC<StatisticCardProps> = ({ title, total, icon, new: newCount, onClick }) => (
  <Card hoverable onClick={onClick}>
    <Statistic title={title} value={total} prefix={icon} />
    <Statistic title='Новые' value={newCount} prefix={<PlusOutlined />} />
  </Card>
)

const Statistics: React.FC = () => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const { maintenance, isLoading } = useAppSelector(state => state.settings)
  const [period, setPeriod] = useState<PeriodType>('day')
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<Api.Response.Statistic | null>(null)
  const [params, setParams] = useState<Api.Params.Statistic>({
    start_date: '',
    end_date: ''
  })

  const url = 'statistic'
  const getData = async () => {
    setLoading(true)
    const today = new Date()
    let startDate = format(today, 'yyyy-MM-dd')
    let endDate = format(today, 'yyyy-MM-dd')

    switch (period) {
      case 'week':
        startDate = format(new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000), 'yyyy-MM-dd')
        break
      case 'month':
        startDate = format(new Date(today.getFullYear(), today.getMonth(), 1), 'yyyy-MM-dd')
        break
      case 'year':
        startDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd')
        break
    }

    const params: Api.Params.Statistic = {
      start_date: startDate,
      end_date: endDate
    }

    const result = await apiGetListWithParams<Api.Response.Statistic>(url, params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      setLoading(false)
      return
    }
    setData(result.data)
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [period])
  useEffect(() => {
    getData().then((data: any) => data && setData(data))
  }, [])
  useEffect(() => {
    dispatch(fetchSiteStatus())
  }, [dispatch, period])

  return (
    <>
      {contextHolder}
      <CoreForm
        title={
          <Flex>
            <FormatField value={!maintenance ? 'Активен' : undefined} type='activeTag' />
          </Flex>
        }
        extra={
          <Flex justify='end' gap={8}>
            <Button type='link' onClick={() => nav(-1)}>
              Назад
            </Button>
          </Flex>
        }
      >
        <Row style={{ marginBottom: 16 }}>
          <Col span={24}>
            <Flex align='center' justify='space-between'>
              <Radio.Group value={period} onChange={e => setPeriod(e.target.value)}>
                <Radio.Button value='day'>День</Radio.Button>
                <Radio.Button value='week'>Неделя</Radio.Button>
                <Radio.Button value='month'>Месяц</Radio.Button>
                <Radio.Button value='year'>Год</Radio.Button>
              </Radio.Group>
            </Flex>
          </Col>
        </Row>
        {data && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <StatisticCard
                title='Пользователи'
                total={data.users_total}
                new={data?.users}
                onClick={() => nav('/admin/user')}
                icon={<UserOutlined />}
              />
            </Col>
            <Col span={12}>
              <StatisticCard
                title='Переводы'
                total={data.translates_total.count}
                new={data?.translates.count}
                onClick={() => nav('/admin/translations')}
                icon={<TranslationOutlined />}
              />
            </Col>
            <Col span={12}>
              <StatisticCard
                title='Транзакции'
                total={data.transactions_total}
                new={data?.transactions}
                onClick={() => nav('/admin/transactions')}
                icon={<TransactionOutlined />}
              />
            </Col>
            <Col span={12}>
              <StatisticCard
                title='Общий баланс (Yoomoney)'
                total={helpers.formatMoney(data.income_total.yoomoney)}
                new={helpers.formatMoney(data?.income.yoomoney)}
                onClick={() => nav('/admin/transactions')}
                icon={<WalletOutlined />}
              />
            </Col>
            <Col span={12}>
              <StatisticCard
                title='Общий баланс (Админ.)'
                total={helpers.formatMoney(data.income_total.admin)}
                new={helpers.formatMoney(data?.income.admin)}
                onClick={() => nav('/admin/transactions')}
                icon={<DollarOutlined />}
              />
            </Col>
            <Col span={12}>
              <StatisticCard
                title='Расходы на переводы'
                total={helpers.formatMoney(data.translates_total.amount)}
                new={helpers.formatMoney(data?.translates.amount)}
                onClick={() => nav('/admin/transactions')}
                icon={<BarChartOutlined />}
              />
            </Col>
            <Col span={24}>
              <StatisticCard
                title='Остаток пользователей'
                total={helpers.formatMoney(data.income_total.yoomoney - data.translates_total.amount)}
                new={helpers.formatMoney(data?.income.yoomoney - data?.translates.amount)}
                onClick={() => nav('/admin/transactions')}
                icon={<WalletOutlined />}
              />
            </Col>
          </Row>
        )}
      </CoreForm>
    </>
  )
}

export default Statistics
