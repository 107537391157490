import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  Row,
  Space,
  Spin,
  message,
} from "antd";
import { useState } from "react";
import { apiGetOne } from "../../api/crud";
import { apiCreatePayment } from "../../api/transaction";
import { apiMe } from "../../api/user";
import { setUser } from "../../store/slices/user";
import { useAppDispatch } from "./../../hooks/useAppDispatch";

const BalanceCharge = ({
  setUpdateTable,
}: {
  setUpdateTable: (value: number) => void;
}) => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 });
  const [balance, setBalance] = useState<number | null>(null);
  const [promo, setPromo] = useState<string | null>("");
  const [spinning, setSpinning] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const updateUserBalance = async () => {
    const result = await apiMe();
    if (result.isError) {
      messageApi.error(
        "Не удалось обновить баланс, пожалуйста обновите страницу вручную"
      );
    } else {
      const user = result.data;
      if (user) {
        dispatch(setUser(user));
      } else {
        messageApi.error(
          "Не удалось обновить баланс, пожалуйста обновите страницу вручную"
        );
      }
    }
  };
  const handleBalance = async (value: number, promo: string) => {
    if (!value || value === null || value < 0) {
      messageApi.error("Некорректное значение суммы");
      return;
    }
    const createURL = await apiCreatePayment(value, promo);
    if (createURL.isError) {
      messageApi.error("Произошла ошибка при попытке оплаты");
    } else {
      setSpinning(true);
      checkTransactionStatus(createURL.data.id);
      window.open(createURL.data.url, "_blank");
    }
  };

  const checkTransactionStatus = async (transactionId: number) => {
    try {
      const response = await apiGetOne("user/transactions", transactionId);

      if (response.isError) {
        console.error("Error checking transaction status:", response.data);
      } else {
        if (response.data.payment_status === "succeeded") {
          messageApi.success(`Пополнение произведено успешно`);
          //trigger to update transactions table, fix if this component will be reused
          setUpdateTable(Math.floor(Math.random() * 1000));
          updateUserBalance();
          setSpinning(false);
        } else if (response.data.payment_status === "pending") {
          setTimeout(() => {
            checkTransactionStatus(transactionId);
          }, 3000);
        } else {
          messageApi.error("Произошла ошибка при попытке оплаты");
        }
      }
    } catch (error) {
      messageApi.error("Произошла ошибка при попытке оплаты");
      console.error("Error checking transaction status:", error);
    }
  };
  return (
    <>
      {contextHolder}
      <Space
        direction="vertical"
        size={16}
        style={{ marginTop: "30px", display: "block" }}
      >
        <Card title="Пополнить баланс">
          <Row gutter={[16, 16]}>
            <Col className="gutter-row" span={24}>
              <Space wrap>
                <InputNumber
                  placeholder="Введите сумму"
                  value={balance}
                  onChange={(event) => setBalance(event)}
                  addonAfter={"руб."}
                  style={{ minWidth: "190px" }}
                  size="large"
                />
                <Input
                  placeholder="Есть промокод?"
                  value={promo as string}
                  onChange={(e) => setPromo(e.target.value)}
                  size="large"
                />
                {spinning ? (
                  <Spin spinning={spinning} fullscreen />
                ) : (
                  <Button
                    size="large"
                    onClick={() =>
                      handleBalance(
                        balance !== null ? balance : 0,
                        promo !== null ? promo : ""
                      )
                    }
                  >
                    Пополнить баланс
                  </Button>
                )}
              </Space>
            </Col>
          </Row>
        </Card>
      </Space>
    </>
  );
};

export default BalanceCharge;
