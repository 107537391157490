import { Modal, Typography, Form, Input, Button } from 'antd'
import { useState } from 'react'

const GetContact = ({ isOpen, onCancel }: { isOpen: boolean; onCancel: any }) => {
  const [form] = Form.useForm()
  const [message, setMessage] = useState('')
  const handleGetDiscont = async ({ contact }: { contact: string }) => {
    const url = `https://api.telegram.org/bot${process.env.REACT_APP_TG_BOT}/sendMessage`
    const body = {
      chat_id: process.env.REACT_APP_TG_CHATID,
      text: `#promo\nКонтакты: ${contact}`
    }
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(data => setMessage('Спасибо за доверие. Промокод скоро будет у вас!'))
      .catch(error => setMessage('Произошла ошибка. Попробуйте повторить позднее'))
    localStorage.setItem('isShowPromoModal', 'true')
  }
  return (
    <Modal
      open={isOpen}
      onOk={() => handleGetDiscont}
      onCancel={() => onCancel(false)}
      title='Получите скидку'
      okText='Пополнить'
      cancelText='Отмена'
      footer={[
        <Button form='myForm' key='submit' type='primary' htmlType='submit' disabled={!!message}>
          Получить промокод
        </Button>
      ]}
    >
      <>
        {!message && (
          <>
            <Typography.Paragraph>
              Сервис еще не запущен, но ранним адептам мы предоставляем промокод на услуги перевода видео со скидкой в
              30% до конца лета
            </Typography.Paragraph>

            <Form form={form} onFinish={handleGetDiscont} id='myForm'>
              <Form.Item label='Email/Telegram' name='contact' rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Form>
          </>
        )}
        <Typography.Title level={5} style={{ margin: 0 }}>
          {message}
        </Typography.Title>
      </>
    </Modal>
  )
}

export default GetContact
