import React from 'react'
import { CoreForm } from '../../components/CoreForm'
import { theme } from 'antd'
import { message } from 'antd'
import { apiGetBlob } from '../../api/crud'
import { useParams, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../hooks/useAppSelector'
import { DynamicStyleLoader } from '../../components/DynamicStyleLoader'

const VideoTest = () => {
  const pageParams = useParams()
  const id = pageParams.id
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const user: Api.Response.User = useAppSelector((state: any) => state.user.data)
  const { useToken } = theme
  const [video, setVideo] = React.useState()
  const [videoDownload, setVideoDownload] = React.useState()
  const [loadingVideo, setLoadingVideo] = React.useState(false)
  const [img, setImg] = React.useState<string>("")
  const getPreview = async () => {
    setLoadingVideo(true)
    try {
      const req = await apiGetBlob(`translate/${id}/download/preview/`)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      //@ts-ignore
      setImg(URL.createObjectURL(req.blob))
    } catch (err) {
      console.error(err)
    }
  }
  const getVideo = async () => {
    try {
      setLoadingVideo(true)
      const req = await apiGetBlob(`translate/${id}/download/video_origin/`)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }

      //@ts-ignore
      setVideoDownload(req.blob)
      //@ts-ignore
      setVideo(URL.createObjectURL(req.blob))
      setLoadingVideo(false)
    } catch (err) {
      console.error(err)
    }
  }
  //Запрашиваем инфу о файле
  React.useEffect(() => {
    getPreview()
    getVideo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DynamicStyleLoader url={'/assets/bootstrap.min.css'} />
      {contextHolder}
      <CoreForm title='Тестовое видео'>
        <video height={'300px'} poster={img} controls style={{ margin: '15px' }}>
          {!loadingVideo && <source src={video} type='video/mp4' />}
        </video>
      </CoreForm>
    </>
  )
}
export default VideoTest
