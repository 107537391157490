import { Modal, Typography, InputNumber } from "antd";
import { useState } from "react";

const BalanceAdd = ({
  isOpen,
  onBalance,
  onCancel,
  waitingCharge,
}: {
  isOpen: boolean;
  onBalance: any;
  onCancel: any;
  waitingCharge?: boolean;
}) => {
  const [balance, setBalance] = useState<number | null>(100);

  return (
    <Modal
      open={isOpen}
      onOk={() => onBalance(balance)}
      onCancel={() => onCancel(false)}
      title="Пополнение баланса"
      okText="Пополнить"
      cancelText="Отмена"
    >
      {waitingCharge ? (
        <>
          <Typography.Paragraph>
            Ожидания платежа, не закрывайте страницу
          </Typography.Paragraph>
        </>
      ) : (
        <>
          <Typography.Paragraph>
            Укажите на какую сумму хотите пополнить баланс:
          </Typography.Paragraph>
          <InputNumber
            value={balance}
            onChange={setBalance}
            addonAfter={"руб."}
          />
        </>
      )}
    </Modal>
  );
};

export default BalanceAdd;
