import { useState, useEffect } from 'react'
import { message, Space } from 'antd'
import { apiCount, apiGetList } from '../../api/crud'
import { FormatField } from '../../components/FormatField'
import { TableData } from '../../components/TableData'
import BalanceCharge from './BalanceCharge'

const Balance = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(10)
  const [updateTable, setUpdateTable] = useState<number>(0)

  // Get all transactions
  const getTransactions = async (params: Api.Params.Pagination) => {
    setLoading(true)
    const result = await apiGetList<Api.Response.Transaction[]>(`user/transactions`, params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')

      setLoading(false)
      return
    }
    setLoading(false)
    return result.data
  }

  const getTotal = async () => {
    const result = await apiCount(`user/transactions`)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      return
    }
    setTotal(result.data)
  }

  useEffect(() => {
    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize
    }
    getTransactions(params).then((data: any) => data && setData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, total, updateTable])

  useEffect(() => {
    getTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const payment_statusText: Record<string, string> = {
    pending: '⌛ Ожидание платежа',
    succeeded: '✅ Успешно',
    canceled: '❌ Неудача'
  }
  const columns = [
    {
      title: 'Дата',
      dataIndex: 'created_at',
      key: 'created_at',
      fixed: 'left',
      render: (value: string) => <FormatField value={value} type='datetime' />
    },
    {
      title: 'Тип',
      dataIndex: 'type_transaction',
      key: 'type_transaction',
      render: (value: 'credit' | 'debit') => <FormatField value={value} type='typeTransaction' />
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number) => <FormatField value={value} type='money' />
    },
    {
      title: 'Состояние',
      dataIndex: 'payment_status',
      key: 'status',
      render: (value: any) => <FormatField value={payment_statusText[value]} type='text' />
    },
    {
      title: 'Промокод',
      dataIndex: 'promocode',
      key: 'promocode',
      render: (value: Api.Response.Promocode | null) =>
        value !== null ? (
          <Space wrap>
            <FormatField value={value.code} type='text' />
            (<FormatField value={value.discount} type='text' /> %)
          </Space>
        ) : (
          <FormatField value={'-'} type='text' />
        )
    }
  ]

  return (
    <>
      {contextHolder}
      <BalanceCharge setUpdateTable={setUpdateTable} />
      <TableData
        loading={loading}
        items={data}
        columns={columns}
        pagination={{ page, pageSize, total, setPage, setPageSize }}
      />
    </>
  )
}

export default Balance
