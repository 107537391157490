import { Steps } from 'antd'

const steps = [
  {
    title: 'Загрузка видео',
    content: 'First-content'
  },
  {
    title: 'Опции перевода',
    content: 'Second-content'
  },
  {
    title: 'Перевод',
    content: 'Three-content'
  },
  {
    title: 'Пост обработка',
    content: 'Last-content'
  },
  {
    title: 'Результат',
    content: 'download'
  }
]
const items = steps.map(item => ({ key: item.title, title: item.title }))
const VideoSteps = ({ current }: { current: number }) => {
  return <Steps current={current} items={items} />
}

export default VideoSteps
