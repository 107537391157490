import { Flex, Form, InputNumber, Space } from "antd";

const FilterFormAmount = () => {
  return (
    <Flex vertical>
      <Space>
        <Form.Item name="amount__gte" label="От">
          <InputNumber min={0} size="small" />
        </Form.Item>
        <Form.Item name="amount__lte" label="До">
          <InputNumber min={1} size="small" />
        </Form.Item>
      </Space>
    </Flex>
  );
};

export default FilterFormAmount;
