import { useAppDispatch } from './../../hooks/useAppDispatch';
import { useEffect, useState } from 'react';
import { apiMe } from './../../api/user'
import { setUser } from '../../store/slices/user'
import { SpinLoader } from './../../components/SpinLoader';
import { useAppSelector } from '../../hooks/useAppSelector';

const UserProvider = ({ children }: { children: any }) => {
    const dispatch = useAppDispatch();
    const user = useAppSelector((state) => state.user.data);
    const [loading, setLoading] = useState(true)

    const getMe = async () => {
        setLoading(true)
        const result = await apiMe()
        if (result.isError) {
            dispatch(setUser(null))
            setLoading(false)
        } else {
            const user = result.data
            if (user) {
                dispatch(setUser(user))
            } else {
                dispatch(setUser(null))
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        if (!user) {
            getMe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <>
        {
            loading
                ? <SpinLoader />
                : children
        }
    </>
}

export default UserProvider