import { Navigate, createBrowserRouter } from 'react-router-dom'
import { Main, Privacy, Rules } from './pages/Main'
import { Auth } from './pages/Auth'
import { Login } from './pages/Auth/Login'
import { Reg } from './pages/Auth/Reg'
import { Forget } from './pages/Auth/Forget'
import { Activate } from './pages/Auth/Activate'
import { useAppSelector } from './hooks/useAppSelector'
import { useNavigate } from 'react-router-dom'
import { Admin } from './pages/Admin'
import { Promo, PromoFormCreate, PromoDetail, PromoForm } from './pages/Promo'
import { User, UserDetail, UserForm, UserFormCreate } from './pages/User'
import { App, Balance } from './pages/App'
import { useEffect, useState } from 'react'
import { SpinLoader } from './components/SpinLoader'
import { VideoList, VideoUpload, VideoSetting, VideoTest } from './pages/Video'
import BalanceNotion from './pages/App/BalanceNotion'
import { Plan, PlanCreate, PlanEdit } from './pages/Plan'
import { Transactions } from './pages/Transactions'
import Profile from './pages/App/Profile'
import { Statistics } from './pages/Statistics'
import { Translations } from './pages/Translations'

const ProtectedRoute = ({ children, roles }: { children: any; roles: ('admin' | 'manager' | 'user')[] }) => {
  const user: Api.Response.User = useAppSelector((state: any) => state.user.data)
  const nav = useNavigate()
  const [data, setData] = useState(<SpinLoader />)

  useEffect(() => {
    if (user && roles.includes(user.role)) {
      setData(children)
    } else {
      nav('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return data
}

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Main />
  },
  {
    path: '/privacy',
    element: <Privacy />
  },
  {
    path: 'rules',
    element: <Rules />
  },
  {
    path: '/auth',
    element: <Auth />,
    children: [
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'reg',
        element: <Reg />
      },
      {
        path: 'forget',
        element: <Forget />
      },
      {
        path: 'activate',
        element: <Activate />
      }
    ]
  },
  {
    path: '/admin',
    element: (
      <ProtectedRoute roles={['admin']}>
        <Admin />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'user',
        element: <User />
      },
      {
        path: 'user/:id',
        element: <UserDetail />
      },
      {
        path: 'user/:id/edit',
        element: <UserForm />
      },
      {
        path: 'user/create',
        element: <UserFormCreate />
      },
      {
        path: 'promo',
        element: <Promo />
      },
      {
        path: 'promo/:id',
        element: <PromoDetail />
      },
      {
        path: 'promo/:id/edit',
        element: <PromoForm />
      },
      {
        path: 'promo/create',
        element: <PromoFormCreate />
      },
      {
        path: 'plan',
        element: <Plan />
      },
      {
        path: 'plan/:id',
        element: <PlanEdit />
      },
      {
        path: 'plan/create',
        element: <PlanCreate />
      },
      {
        path: 'transactions',
        element: <Transactions />
      },
      {
        path: 'statistics',
        element: <Statistics />
      },
      {
        path: 'translations',
        element: <Translations />
      }
    ]
  },
  {
    path: '/app',
    element: (
      <ProtectedRoute roles={['user', 'admin']}>
        <App />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/app/profile',
        element: <Profile />
      },
      {
        path: '/app/list',
        element: <VideoList />
      },
      {
        path: '/app/balance',
        element: <Balance />
      },
      {
        path: 'add',
        element: <VideoUpload />
      },
      {
        path: 'add/:id',
        element: <VideoSetting />
      },
      {
        path: 'test/:id',
        element: <VideoTest />
      }
    ]
  },
  {
    path: '/transactions/:id',
    element: (
      <ProtectedRoute roles={['user']}>
        <BalanceNotion />
      </ProtectedRoute>
    )
  },
  {
    path: '*',
    element: <Navigate to='/' />
  }
])
