import { Flex, Popover } from 'antd'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
const instructions = (
  <div>
    <h2>Поддерживаемые языки</h2>
    <ul>
      <li>Язык переводимого видео: Русский, Английский</li>
      <li>
        Исходящий язык перевода:
        <ul>
          <li>Английский</li>
          <li>Русский</li>
          <li>Китайский</li>
          <li>Немецкий</li>
          <li>Французский</li>
          <li>Итальянский</li>
          <li>Испанский</li>
          <li>Португальский</li>
          <li>Турецкий</li>
        </ul>
      </li>
    </ul>
    <h2>Требования к видео</h2>
    <ul>
      <li>Для правильной работы lipsync на видео в кадре за раз должно быть не более двух человек</li>
      <li>
        Для правильной работы клонирования речи в видео каждый спикер должен говорить по очереди, не параллельно с
        другим
      </li>
      <li>Расширение видео от 360х360 до 4096х4096</li>
      <li>Размер файла от 5 Мб до 1 Гб</li>
      <li>Длина видео от 5 секунд до 60 минут</li>
    </ul>
  </div>
)
const InstructionUpload = () => {
  const [arrow, setArrow] = useState('Show')
  const mergedArrow = useMemo(() => {
    if (arrow === 'Hide') {
      return false
    }

    if (arrow === 'Show') {
      return true
    }

    return {
      pointAtCenter: true
    }
  }, [arrow])
  return (
    <Flex style={{ justifyContent: 'flex-end', padding: '10px 0px' }}>
      {' '}
      <Popover placement='left' content={instructions} arrow={mergedArrow}>
        <Link to='#'> Рекомендации по загрузке видео </Link>
      </Popover>{' '}
    </Flex>
  )
}
export default InstructionUpload
