import { Button, Table, Flex } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type TableDataProps = {
    loading?: boolean;
    buttons?: Array<TableDataType.Button>;
    columns: TableDataType.Column[];
    items: Array<any> | undefined;
    routeDetail?: TableDataType.RouteDetail;
    pagination: TableDataType.Pagination;
    handleTableChange?: any
    
}


const TableData = (props: TableDataProps) => {
    const navigate = useNavigate()
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;


    return <Flex vertical>
        <Flex justify="end" gap={8} align="baseline" style={{ marginBottom: "16px" }}>
            <Flex>{hasSelected ? `Выбрано: ${selectedRowKeys.length}` : ''}</Flex>
            <Flex gap={8} >
                {props.buttons?.map((button, key) => (
                    <Button key={key} type={button.type} danger={button.danger} onClick={() => {
                        return button.onClick ? button.onClick() : button.onClickWithSelectedRow ? button.onClickWithSelectedRow(selectedRowKeys) : '';
                    }} disabled={button.onClick ? false : !hasSelected}>{button.title}</Button>
                ))}
            </Flex>
        </Flex>

        <Table
            loading={props.loading}
            style={{ cursor: 'pointer' }}
            dataSource={props.items}
            columns={props.columns}
            scroll={{x: 600}}
            onChange={props.handleTableChange && props.handleTableChange}
            pagination={{
                defaultCurrent: 1,
                total: props.pagination.total,
                pageSize: props.pagination.pageSize,
                onChange: (page, pageSize) => { props.pagination.setPage(page); props.pagination?.setPageSize(pageSize) },
                current: props.pagination.page,
            }}
            rowKey='id'
            rowSelection={(props.routeDetail !== undefined) ? rowSelection : undefined}
            onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        if (props.routeDetail !== undefined) {
                            navigate(`${window.location.pathname}/${record['id']}`)
                        }
                    }
                };
            }} />
    </Flex>

}

export default TableData