import type { TabsProps } from 'antd';
import { Tabs, Typography, Flex, Button } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import UserTransaction from './UserTransaction';
import UserInfo from './UserInfo';
const UserDetail = () => {
    const pageParams = useParams();
    const id = pageParams.id
    const nav = useNavigate()

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Общая информация',
            children: <UserInfo id={id} />
        },
        {
            key: '2',
            label: 'Транзакции',
            children: <UserTransaction id={id} />
        },
    ];

    return <>
        <Flex justify='space-between' align='center' >
            <Typography.Title level={3} style={{ alignSelf: 'center' }}>Пользователь {id}</Typography.Title>
        </Flex>
        <Tabs defaultActiveKey="1" items={tabs} tabBarExtraContent={<Flex justify='end' gap={8}><Button type="link" onClick={() => nav(-1)}>Назад</Button><Button type="primary" onClick={() => nav(`/admin/user/${id}/edit`)}>Изменить</Button></Flex>} />
    </>

}
export default UserDetail