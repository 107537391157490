import { Badge, Button, Card, Flex, Spin, Tag, Typography } from 'antd'
import preview from '../../../assets/video-load.gif'
import { useNavigate } from 'react-router-dom'

const ProcessingVideo = ({ info, status }: { info: Api.Response.VideoTranslate; status: string }) => {
  const nav = useNavigate()

  return (
    <Flex align='center' justify='center' gap={20} style={{ paddingTop: '40px' }}>
      <Card title={`Обработка видео (${info?.video.name})`} style={{ width: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <p>
            <Tag color='green' style={{ fontSize: '14px' }}>
              {status}
            </Tag>
          </p>
          <span>
            <img src={preview} alt='Превью видео' height={'200px'} style={{ margin: '15px' }} />
          </span>
          <Typography.Title level={4}>Скоро видео будет готово</Typography.Title>
          <Typography.Paragraph>
            Вы можете спокойно закрыть эту страницу и вернуться сюда через раздел "Мои видео" в любой момент, ваш ролик
            будет вас ждать. <br /> А пока можно загрузить еще видео!
          </Typography.Paragraph>
          <Flex align='center' justify='center' gap={10}>
            <Button type='primary' onClick={() => nav('/app/add')}>
              Загрузить еще одно
            </Button>
            <Button type='default' onClick={() => nav('/app/list')}>
              Мои видео
            </Button>
          </Flex>
        </div>
      </Card>
    </Flex>
  )
}
export default ProcessingVideo
