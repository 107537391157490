import { apiCreate } from '../../api/crud'
import { Flex, Form, message, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FormatFormInput } from '../../components/FormatFormInput'
import { CoreForm } from '../../components/CoreForm'

const PromoFormCreate = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const nav = useNavigate()
  const [form] = Form.useForm()
  const url = 'promocode'

  const handleSave = async (values: any) => {
    const result = await apiCreate<Api.Response.Promocode>(url, values)
    if (result.isError) {
      messageApi.error('Произошла ошибка при создании пользователя')
    } else {
      nav(`/admin/promocode/${result.data.id}`)
    }
  }

  return (
    <>
      {contextHolder}
      <CoreForm
        title='Создание промокода'
        extra={
          <Flex gap={8}>
            <Button type='primary' onClick={() => form.submit()}>
              Сохранить
            </Button>
            <Button type='primary' danger onClick={() => nav(-1)}>
              Отмена
            </Button>
          </Flex>
        }
      >
        <Form
          form={form}
          labelCol={{ span: 4 }}
          initialValues={{ is_active: true, valid_at: null, valid_count: 0 }}
          onFinish={handleSave}
        >
          <FormatFormInput label='Активен' name='is_active' typeData='boolean' />
          <FormatFormInput label='Промодкод' name='code' typeData='text' />
          <FormatFormInput label='Скидка' name='discount' typeData='number' />
          <FormatFormInput label='Доступен до' name='valid_at' typeData='date' />
          <FormatFormInput label='Количество' name='valid_count' typeData='number' />
        </Form>
      </CoreForm>
    </>
  )
}

export default PromoFormCreate
