import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import { apiGetOne, apiUpdate } from "../../api/crud";
import { CoreForm } from "../../components/CoreForm";
import { useParams } from "react-router-dom";
import PlanForm from "./PlanForm";

const PlanEdit = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 });
  const pageParams = useParams();
  const id = pageParams.id;
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [data, setData] = useState<Api.Response.Plan>();

  const getData = async () => {
    if (id) {
      const result = await apiGetOne("plan", Number.parseInt(id));
      if (result.isError) {
        setLoading(true);
        return;
      }
      setLoading(false);
      return result.data;
    }
  };

  useEffect(() => {
    if (loading) {
      getData().then((data) => {
        setData(data);
        form.setFieldsValue(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async (values: Api.Response.Plan) => {
    if (id) {
      const result = await apiUpdate("plan", parseInt(id), values);
      if (result.isError) {
        messageApi.error("Произошла ошибка при изменении тарифа");
      } else {
        messageApi.success("Изменения успешно сохранены");
      }
    }
  };
  return (
    <CoreForm title="Тарифы">
    {contextHolder}
      <PlanForm startValue={data} handleSave={handleSave} form={form}/>
    </CoreForm>
  );
};

export default PlanEdit;
