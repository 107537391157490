import { CoreForm } from '../../components/CoreForm'
import { DeleteConfirm } from '../../components/modals/DeleteConfirm'
import { Flex, Form, message, Button, Dropdown } from 'antd'
import { FormatFormInput } from '../../components/FormatFormInput'
import { apiUpdate, apiGetOne, apiDelete } from '../../api/crud'
import { useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import type { MenuProps } from 'antd'

const PromoForm = () => {
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const nav = useNavigate()
  const pageParams = useParams()
  const id = pageParams.id
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()
  const [form] = Form.useForm()
  const url = 'promocode'
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const getData = async () => {
    if (id) {
      const result = await apiGetOne(url, Number.parseInt(id))
      if (result.isError) {
        setLoading(true)
        messageApi.error('Ошибка загрузки')
        return
      }
      setLoading(false)
      return result.data
    }
  }

  useEffect(() => {
    if (loading) {
      getData().then(data => {
        setData(data)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSave = async (values: any) => {
    if (id) {
      const result = await apiUpdate<Api.Response.Promocode>(url, parseInt(id), values)
      if (result.isError) {
        messageApi.error('Произошла ошибка при изменении')
      } else {
        messageApi.success('Изменения успешно сохранены')
      }
    }
  }

  const handleDelete = async () => {
    if (id) {
      const result = await apiDelete(url, parseInt(id))
      if (result.isError) {
        messageApi.error('Произошла ошибка при удалении')
      } else {
        nav('/admin/promocode')
      }
    }
  }

  const onMenuClick: MenuProps['onClick'] = async e => {
    switch (e.key) {
      case '1':
        setDeleteModalOpen(true)
        break
      case '2':
        nav(-1)
        break
    }
  }

  const menuItems: MenuProps['items'] = [
    {
      key: '1',
      label: 'Удалить',
      danger: true
    },
    {
      type: 'divider'
    },
    {
      key: '2',
      label: 'Отмена'
    }
  ]

  return (
    <>
      {contextHolder}
      {!loading && (
        <>
          <DeleteConfirm isOpen={deleteModalOpen} onOk={handleDelete} onCancel={setDeleteModalOpen} />
          <CoreForm
            title={`Промокод ${id}`}
            extra={
              <Flex gap={8}>
                <Button type='link' onClick={() => nav(-1)}>
                  Назад
                </Button>
                <Dropdown.Button menu={{ items: menuItems, onClick: onMenuClick }} onClick={form.submit}>
                  Сохранить
                </Dropdown.Button>
              </Flex>
            }
          >
            <Form form={form} labelCol={{ span: 4 }} initialValues={data} onFinish={handleSave}>
              <FormatFormInput label='Активен' name='is_active' typeData='boolean' />
              <FormatFormInput label='Промодкод' name='code' typeData='text' />
              <FormatFormInput label='Скидка' name='discount' typeData='number' />
              <FormatFormInput label='Доступен до' name='valid_at' typeData='date' />
              <FormatFormInput label='Количество' name='valid_count' typeData='number' />
            </Form>
          </CoreForm>
        </>
      )}
    </>
  )
}

export default PromoForm
