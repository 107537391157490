import React from "react";
import { Layout, theme, Button, Result } from "antd";
import { useParams, Link } from "react-router-dom";
import { apiGetOne } from "../../api/crud";
import { HeaderLogo } from "../../components/HeaderLogo";
import { LoginLogout } from "../../components/LoginLogout";
import { ClockCircleTwoTone } from "@ant-design/icons";

const { Header, Content } = Layout;
const { useToken } = theme;

const BalanceNotion = () => {
  const pageParams = useParams();
  const id = pageParams.id;
  const { token } = useToken();
  const [data, setData] = React.useState<Api.Response.СheckTransaction>();

  React.useEffect(() => {
    checkTransactionStatus(id ? parseInt(id) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkTransactionStatus = async (transactionId: number | null) => {
    if (transactionId !== null) {
      try {
        const response = await apiGetOne("user/transactions", transactionId);
        setData(response.data);

        if (response.isError) {
          console.error("Error checking transaction status");
        } else {
          if (response.data.payment_status === "pending") {
            setTimeout(() => {
              checkTransactionStatus(transactionId);
            }, 3000);
          }
        }
      } catch (error) {
        console.error("Error checking transaction status:", error);
      }
    }
  };

  return (
    <>
      <Layout
        style={{ paddingLeft: "10%", paddingRight: "10%", minHeight: "100vh" }}
      >
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: token.colorBgBase,
            borderRadius: token.borderRadiusLG,
          }}
        >
          <HeaderLogo />
          <LoginLogout />
        </Header>
        <Content>
          {data?.payment_status === "succeeded" && (
            <Result
              status="success"
              title={`Оплата прошла успешно!`}
              subTitle={`Баланс пополнен на: ${data.amount} рублей. ID транзакции: ${data.payment_id}`}
              extra={[
                <Link to="/app/balance" key="console">
                  <Button type="primary">В панель</Button>
                </Link>,

                <Button key="more">Пополнить еще</Button>,
              ]}
            />
          )}

          {data?.payment_status === "pending" && (
            <Result
              icon={<ClockCircleTwoTone />}
              title={`Идет обработка транзакции либо ожидание оплаты, если оплата не прошла - обратитесь в поддержку, ID платежа: ${data.payment_id}`}
            />
          )}
          {data?.payment_status === "canceled" && (
            <Result
              status="error"
              title="Оплата отменена"
              subTitle={`Оплата отменена либо прошла неудачно, пожалуйста свяжитесь с нами сообщив код транзакции: ${data.payment_id}.`}
              extra={[
                <Button type="primary" key="console">
                  Вернуться в панель
                </Button>,
                <Button key="buy">Попробовать еще раз</Button>,
              ]}
            />
          )}
          {data?.detail === "Not found" && (
            <Result
              status="404"
              title="404"
              subTitle="Извините, такой страницы не существует"
              extra={<Button type="primary">На главную</Button>}
            />
          )}
        </Content>
      </Layout>
    </>
  );
};

export default BalanceNotion;
