
export type CoreSectionProps = {
    children: string | JSX.Element | JSX.Element[]
}

export const CoreSection = ({children} : CoreSectionProps) => {
    return <section style={{ paddingTop: '64px'}}>
        {children}
    </section>
}
