import { MetrikaCounter } from 'react-metrika'

export const MetrikaWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <MetrikaCounter
        id={94541116}
        options={{
          trackHash: true,
          webvisor: true
        }}
      />
      {children}
    </>
  )
}
