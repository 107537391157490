import { Flex, Form, Select } from "antd";
const field = {
    "": "Все",
    "succeeded": 'Успешная',
    "pending": 'Ожидание',
    "waiting_for_capture": 'Холдированные',
    "canceled": 'Не успешные'
}
const FilterFormTransType = () => {
  return (
    <Flex vertical>
        <Form.Item name="payment_status__in">
          <Select
            style={{ marginLeft: "5px", width: "100%" }}
            placeholder="Тип транзакции"
            size="small"
            options={Object.entries(field).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item>
    </Flex>
  );
};

export default FilterFormTransType;
