import { useState, useEffect } from 'react'
import { message, Space } from 'antd'
import { apiCount, apiGetListWithParams } from '../../api/crud'
import { FormatField } from '../../components/FormatField'
import { TableData } from '../../components/TableData'
import { Link } from 'react-router-dom'
import {
  FilterFormMain,
  FilterFormDate,
  FilterFormPromo,
  FilterFormUser,
  FilterFormAmount,
  FilterFormStatus,
  FilterFormTransType
} from './FilterForm'

const payment_statusText: Record<string, string> = {
  pending: '⌛ Ожидание',
  waiting_for_capture: '⌛ Холдирование',
  succeeded: '✅ Успешная',
  canceled: '❌ Не успешная'
}

const Transactions = () => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [total, setTotal] = useState<number>(10)

  const [params, setParams] = useState<Api.Params.Filtration>({
    offset: (page - 1) * pageSize,
    limit: pageSize
  })
  //Очистка входных даннных для удаление устаревших фильтров из GET
  const clearInputData = (value: object) => {
    const data = Object.fromEntries(
      Object.entries(value).filter(([key, value]) => value !== null && value !== undefined && value !== '')
    )
    return data
  }
  const getTransactions = async (params: Api.Params.Filtration) => {
    setLoading(true)
    const result = await apiGetListWithParams<Api.Response.Transaction[]>(`transaction`, params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')

      setLoading(false)
      return
    }
    setLoading(false)
    return result.data
  }

  const getTotal = async () => {
    const result = await apiCount(`transaction`)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      return
    }
    setTotal(result.data)
  }

  //handle params change
  useEffect(() => {
    getTransactions(params).then((data: any) => data && setData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, page])

  //for paginator
  useEffect(() => {
    getTotal()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //Handle page change
  useEffect(() => {
    setParams(prevParams => ({
      ...prevParams,
      offset: (page - 1) * pageSize,
      limit: pageSize
    }))
  }, [page, pageSize, total])

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const filteredFilters = clearInputData(filters)

    setParams(prevParams => {
      const updatedParams: any = { ...prevParams }
      // Удаляем все ключи, которые пришли в values
      for (const key in filters) {
        delete updatedParams[key]
      }
      // Добавляем заново только ключи из filteredValues
      for (const key in filteredFilters) {
        updatedParams[key] = filteredFilters[key]
      }
      updatedParams.order_by = sorter.column ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}` : '-id'
      return updatedParams
    })
  }

  const handleSave = async (values: any) => {
    const filteredFilters = clearInputData(values)

    setParams(prevParams => {
      const updatedParams: any = { ...prevParams }
      // Удаляем все ключи, которые пришли в values
      for (const key in values) {
        delete updatedParams[key]
      }
      // Добавляем заново только ключи из filteredValues
      for (const key in filteredFilters) {
        updatedParams[key] = filteredFilters[key]
      }
      return updatedParams
    })
    setPage(1)
  }

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: true,
      filterDropdown: () => {
        return (
          <FilterFormMain handleSave={handleSave}>
            <FilterFormDate />
          </FilterFormMain>
        )
      },
      render: (value: string) => <FormatField value={value} type='datetime' />
    },
    {
      title: 'Тип',
      dataIndex: 'type_transaction',
      key: 'type_transaction',
      sorter: true,
      filterDropdown: () => {
        return (
          <FilterFormMain handleSave={handleSave}>
            <FilterFormTransType />
          </FilterFormMain>
        )
      },
      render: (value: 'credit' | 'debit') => <FormatField value={value} type='typeTransaction' />
    },
    {
      title: 'Сумма',
      dataIndex: 'amount',
      sorter: true,
      key: 'amount',
      filterDropdown: () => {
        return (
          <FilterFormMain handleSave={handleSave}>
            <FilterFormAmount />
          </FilterFormMain>
        )
      },
      render: (value: number) => <FormatField value={value} type='money' />
    },
    {
      title: 'Владелец транзакции',
      dataIndex: 'owner',
      key: 'owner',
      filterDropdown: () => {
        return (
          <FilterFormMain handleSave={handleSave}>
            <FilterFormUser />
          </FilterFormMain>
        )
      },
      render: (value: Api.Response.User) => (
        <Link to={`/admin/user/${value.id}`}>
          <Space wrap>
            <FormatField value={value.role} type='role' />
            <FormatField value={value.lastname} type='text' />
            <FormatField value={value.firstname} type='text' />
          </Space>
        </Link>
      )
    },
    {
      title: 'Состояние',
      dataIndex: 'payment_status',
      key: 'payment_status',
      sorter: true,
      filterDropdown: () => {
        return (
          <FilterFormMain handleSave={handleSave}>
            <FilterFormStatus />
          </FilterFormMain>
        )
      },
      render: (value: any) => <FormatField value={payment_statusText[value]} type='text' />
    },
    {
      title: 'Промокод',
      dataIndex: 'promocode',
      key: 'promocode',
      filterDropdown: () => {
        return (
          <FilterFormMain handleSave={handleSave}>
            <FilterFormPromo />
          </FilterFormMain>
        )
      },
      render: (value: Api.Response.Promocode | null) =>
        value !== null ? (
          <Space wrap>
            <FormatField value={value.code} type='text' />
            (<FormatField value={value.discount} type='text' /> %)
          </Space>
        ) : (
          <FormatField value={'-'} type='text' />
        )
    }
  ]

  return (
    <>
      {contextHolder}
      <TableData
        loading={loading}
        items={data}
        columns={columns}
        pagination={{ page, pageSize, total, setPage, setPageSize }}
        handleTableChange={handleTableChange}
      />
    </>
  )
}

export default Transactions
