import React from 'react'
import { Form, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const FilterFormMain = ({
  children,
  handleSave,
}: {
  children: any;
  handleSave: any;
}) => {
  const [form] = Form.useForm();

  const handleReset = () => {
    form.resetFields();
    form.submit()
  }
  return (
    <Form form={form} labelCol={{ span: 4 }} onFinish={handleSave} style={{padding: 18}}>
      {children && React.cloneElement(children as React.ReactElement<any>, { form })}
      <Space>
          <Button
            type="primary"
            onClick={form.submit}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button
            size="small"
            style={{ width: 90 }}
            onClick={handleReset}
          >
            Сброс
          </Button>
      </Space>
    </Form>
  );
};

export default FilterFormMain;
