import { useEffect, useState } from 'react'
import { apiGetOne } from '../../api/crud'
import { FormatField } from '../../components/FormatField'
import { CoreForm } from '../../components/CoreForm'
import { Descriptions, Flex, Skeleton, Tag } from 'antd'
import type { DescriptionsProps } from 'antd'

const UserInfo = ({ id }: { id: string | undefined }) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState<DescriptionsProps['items']>([])
  const [active, setAcive] = useState(false)
  const [role, setRole] = useState('user')
  const [avatar, setAvatar] = useState(null)

  const getData = async () => {
    if (id) {
      const result = await apiGetOne('user', Number.parseInt(id))
      if (result.isError) {
        setLoading(true)
        return
      }
      setLoading(false)
      return result.data
    }
  }

  useEffect(() => {
    if (loading) {
      getData().then(data => {
        setAcive(data?.is_active)
        setRole(data?.role)
        setAvatar(data?.avatar)
        const _items: DescriptionsProps['items'] = [
          {
            label: 'Фамилия',
            span: 3,
            children: <FormatField value={data?.lastname} type='text' />
          },
          {
            label: 'Имя',
            span: 3,
            children: <FormatField value={data?.firstname} type='text' />
          },
          {
            label: 'Баланс',
            span: 3,
            children: <FormatField value={data?.balance} type='money' />
          },
          {
            label: 'Электронная почта',
            span: 3,
            children: <FormatField value={data?.email} type='email' />
          },

          {
            label: 'Номер телефона',
            span: 3,
            children: <FormatField value={data?.phone} type='phone' />
          },
          {
            label: 'Телеграмм',
            span: 3,
            children: <FormatField value={data?.telegram} type='telegram' />
          }
        ]
        setItems(_items)
      })
    }
  })
  return (
    <>
      {items && (
        <CoreForm title=''>
          <Flex align='start' justify='start'>
            <Descriptions layout='horizontal' items={items} />
            <Flex vertical gap={8}>
              {avatar ? <img src={avatar} alt='' /> : <Skeleton.Image />}
              {active ? <Tag color='green'>Активен</Tag> : <Tag color='red'>Неактивен</Tag>}
              <FormatField value={role} type='role' />
            </Flex>
          </Flex>
        </CoreForm>
      )}
    </>
  )
}

export default UserInfo
