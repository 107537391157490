export const languageOptions = [
  {
    value: 'en',
    label: 'Английский',
    icon: '/assets/icons/flags/GB.svg'
  },
  {
    value: 'am',
    label: 'Американский',
    icon: '/assets/icons/flags/AM.svg'
  },
  {
    value: 'de',
    label: 'Немецкий',
    icon: '/assets/icons/flags/DE.svg'
  },
  {
    value: 'hi',
    label: 'Индийский',
    icon: '/assets/icons/flags/HI.svg'
  },
  {
    value: 'es',
    label: 'Испанский',
    icon: '/assets/icons/flags/ES.svg'
  },
  {
    value: 'it',
    label: 'Итальянский',
    icon: '/assets/icons/flags/IT.svg'
  },
  {
    value: 'zh',
    label: 'Китайский',
    icon: '/assets/icons/flags/CN.svg'
  },
  {
    value: 'ko',
    label: 'Корейский',
    icon: '/assets/icons/flags/KO.svg'
  },
  {
    value: 'pt',
    label: 'Португальский',
    icon: '/assets/icons/flags/PT.svg'
  },
  {
    value: 'ru',
    label: 'Русский',
    icon: '/assets/icons/flags/RU.svg'
  },
  {
    value: 'tr',
    label: 'Турецкий',
    icon: '/assets/icons/flags/TR.svg'
  },
  {
    value: 'uz',
    label: 'Узбекский',
    icon: '/assets/icons/flags/UZ.svg'
  },
  {
    value: 'fr',
    label: 'Французский',
    icon: '/assets/icons/flags/FR.svg'
  },
  {
    value: 'ja',
    label: 'Японский',
    icon: '/assets/icons/flags/JP.svg'
  },
  {
    value: 'af-ZA',
    label: 'Африканский (ЮАР)',
    icon: '/assets/icons/flags/ZA.svg'
  },
  {
    value: 'ar-EG',
    label: 'Арабский (Египет)',
    icon: '/assets/icons/flags/EG.svg'
  },
  {
    value: 'fa-IR',
    label: 'Персидский',
    icon: '/assets/icons/flags/IR.svg'
  },
  {
    value: 'ar-AE',
    label: 'Арабский (ОАЭ)',
    icon: '/assets/icons/flags/AE.svg'
  },
  {
    value: 'az-AZ',
    label: 'Азербайджанский',
    icon: '/assets/icons/flags/AZ.svg'
  },
  {
    value: 'cs-CZ',
    label: 'Чешский',
    icon: '/assets/icons/flags/CZ.svg'
  },
  {
    value: 'da-DK',
    label: 'Датский',
    icon: '/assets/icons/flags/DK.svg'
  },
  {
    value: 'nl-BE',
    label: 'Нидерландский (Бельгия)',
    icon: '/assets/icons/flags/BE.svg'
  },
  {
    value: 'ka-GE',
    label: 'Грузинский',
    icon: '/assets/icons/flags/GE.svg'
  },
  {
    value: 'sr-RS',
    label: 'Сербский',
    icon: '/assets/icons/flags/RS.svg'
  },
  {
    value: 'el-GR',
    label: 'Греческий',
    icon: '/assets/icons/flags/GR.svg'
  },
  {
    value: 'he-IL',
    label: 'Иврит',
    icon: '/assets/icons/flags/IL.svg'
  },
  {
    value: 'hu-HU',
    label: 'Венгерский',
    icon: '/assets/icons/flags/HU.svg'
  },
  {
    value: 'id-ID',
    label: 'Индонезийский',
    icon: '/assets/icons/flags/ID.svg'
  },
  {
    value: 'kk-KZ',
    label: 'Казахский',
    icon: '/assets/icons/flags/KZ.svg'
  },
  {
    value: 'ms-MY',
    label: 'Малазийский (Малайзия)',
    icon: '/assets/icons/flags/MY.svg'
  },
  {
    value: 'th-TH',
    label: 'Тайский',
    icon: '/assets/icons/flags/TH.svg'
  }
]
