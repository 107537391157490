import logo from './../../assets/logo.png'
import { Row, Typography, Col } from 'antd'
import { Link } from 'react-router-dom'

const HeaderLogo = ({ size = 'original' }: { size?: 'big' | 'original' }) => {
  let sizeImg = '52px'
  let levelHeader: 1 | 2 | 3 | 4 | 5 = 5
  if (size === 'big') {
    sizeImg = '100px'
    levelHeader = 3
  }
  return (
    <Link to='/'>
      <Row align={'middle'}>
        <Col sm={12}>
          <img
            src={logo}
            alt=''
            width={sizeImg}
            style={{
              verticalAlign: 'middle'
            }}
          />
        </Col>
        <Col xs={0} sm={12}>
          <Typography.Title level={levelHeader} style={{ margin: 0, wordBreak: 'normal' }}>
            Voicecover
          </Typography.Title>
        </Col>
      </Row>
    </Link>
  )
}

export default HeaderLogo
