import { useState, useEffect } from 'react'
import { apiCount, apiGetList } from '../../api/crud'
import { FormatField } from '../../components/FormatField'
import { TableData } from '../../components/TableData'
import { message, Flex } from 'antd'

const UserTransaction = ({ id }: { id: string | undefined }) => {
  const [data, setData] = useState()
  const [loading, setLoading] = useState(true)
  const [messageApi, contextHolder] = message.useMessage({ duration: 5 })
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState<number>(10)

  const getData = async (params: Api.Params.Pagination) => {
    setLoading(true)
    const result = await apiGetList<Api.Response.Transaction[]>(`transaction/user/${id}`, params)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')

      setLoading(false)
      return
    }
    setLoading(false)
    return result.data
  }

  const getTotal = async () => {
    const result = await apiCount(`transaction/user/${id}`)
    if (result.isError) {
      messageApi.error('Ошибка загрузки данных')
      return
    }
    setTotal(result.data)
  }

  useEffect(() => {
    const params = {
      offset: (page - 1) * pageSize,
      limit: pageSize
    }
    getData(params).then((data: any) => data && setData(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, total])

  useEffect(() => {
    getTotal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      title: 'Дата',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (value: string) => <FormatField value={value} type='datetime' />
    },
    {
      title: 'Тип',
      dataIndex: 'type_transaction',
      key: 'type_transaction',
      render: (value: 'credit' | 'debit') => <FormatField value={value} type='typeTransaction' />
    },
    {
      title: 'Количество',
      dataIndex: 'amount',
      key: 'amount',
      render: (value: number) => <FormatField value={value} type='money' />
    },
    {
      title: 'Владелец транзакции',
      dataIndex: 'owner',
      key: 'owner',
      render: (value: Api.Response.User) => (
        <Flex gap={4}>
          <FormatField value={value.role} type='role' />
          <FormatField value={value.lastname} type='text' />
          <FormatField value={value.firstname} type='text' />
        </Flex>
      )
    },
    {
      title: 'Промокод',
      dataIndex: 'promocode',
      key: 'promocode',
      render: (value: Api.Response.Promocode | null) =>
        value !== null ? (
          <Flex gap={4}>
            <FormatField value={value.code} type='text' />
            (<FormatField value={value.discount} type='text' /> %)
          </Flex>
        ) : (
          <FormatField value={'-'} type='text' />
        )
    }
  ]

  return (
    <>
      {contextHolder}
      <TableData
        loading={loading}
        items={data}
        columns={columns}
        pagination={{ page, pageSize, total, setPage, setPageSize }}
      />
    </>
  )
}

export default UserTransaction
