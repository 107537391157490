import React, { SyntheticEvent, MouseEvent } from 'react'

import './video-player.scss'

const videoControllHiddingDelay = 2

type Props = {
  videoUrl: string
  onTimeUpdate?: (timePerSecond: number) => void
  onError?: (e: SyntheticEvent) => void
  onDurationChanges: (duration: number) => void
  height?: number
}
export default class VideoPlayer extends React.Component<Props> {
  videoElementRef: React.RefObject<HTMLVideoElement>
  timer: number = 0 // for settimeout

  state: {
    lastStart: number
    showVideoControll: boolean
  }

  constructor(props: any) {
    super(props)

    this.state = {
      lastStart: 0,
      showVideoControll: false
    }

    this.videoElementRef = React.createRef()

    // --- register methods ---
    // events
    this.onTimeUpdate = this.onTimeUpdate.bind(this)
    this.onMouseMove = this.onMouseMove.bind(this)
    this.onError = this.onError.bind(this)

    // actions
    this.setPlay = this.setPlay.bind(this)
    this.isPlaying = this.isPlaying.bind(this)
    this.disableVideoControllers = this.disableVideoControllers.bind(this)

    this.onDurationChanges = this.onDurationChanges.bind(this)
  }

  // ----------------------- events ------------------------

  onDurationChanges() {
    // emit signal
    if (this.props.onDurationChanges) {
      const du = this.videoElementRef.current?.duration || 0
      this.props.onDurationChanges(du)
    }
  }

  onTimeUpdate(e: SyntheticEvent<HTMLVideoElement, Event>) {
    if (this.props.onTimeUpdate) {
      const currentTime = e.currentTarget.currentTime
      this.props.onTimeUpdate(currentTime)
    }
  }

  onMouseMove(e: MouseEvent) {
    if (this.timer) clearTimeout(this.timer)

    this.setState({ showVideoControll: true })
    this.timer = +setTimeout(this.disableVideoControllers, videoControllHiddingDelay * 1000)
  }

  onError(e: SyntheticEvent) {
    if (this.props.onError) this.props.onError(e)
  }

  // // ----------------------- functions ------------------------

  disableVideoControllers() {
    clearTimeout(this.timer)
    this.setState({ showVideoControll: false })
  }

  setTime(timePerSeconds: number) {
    const ve = this.videoElementRef.current
    if (ve) {
      //   ve.pause();
      ve.currentTime = timePerSeconds
      //   ve.play();
    }
  }

  shootTime(seconds: number) {
    const ve = this.videoElementRef.current

    if (ve) {
      const currentTime = ve.currentTime
      this.setTime(currentTime + seconds)
    }
  }

  setPlay(play: boolean) {
    if (this.videoElementRef.current) {
      if (play) this.videoElementRef.current?.play()
      else this.videoElementRef.current?.pause()
    }
  }

  togglePlay() {
    this.setPlay(!this.isPlaying())
  }

  isPlaying(): boolean {
    return Boolean(this.videoElementRef.current && !this.videoElementRef.current.paused)
  }

  // -------------------------- component API ---------------------------
  render() {
    const isPlaying = this.isPlaying()

    return (
      <div
        className={'video-player ' + (this.state.showVideoControll ? 'show ' : '')}
        onMouseMove={this.onMouseMove}
        onMouseOut={this.disableVideoControllers}
      >
        <div className='video-screen' onContextMenu={e => e.preventDefault()}>
          <video
            height={this.props.height}
            onTimeUpdate={this.onTimeUpdate}
            ref={this.videoElementRef}
            autoPlay={false}
            loop={false}
            onDurationChange={this.onDurationChanges}
            onError={e => this.onError(e)}
            // controls={true}
            src={this.props.videoUrl}
          />

          <div className='video-state-controller'>
            <div className='part'></div>

            <div className='part action-btn-group'>
              <div className='action-btn play-pause' onClick={() => this.setPlay(!isPlaying)}>
                <span className={'icon fas fa-3x ' + (isPlaying ? 'fa-pause' : 'fa-play')}></span>
              </div>
            </div>

            <div className='part'></div>
          </div>
        </div>
      </div>
    )
  }
}
