import handlerFetch from "./fetch"
export const apiReg = async (data: Api.Auth.Reg) => {
    return await handlerFetch<Api.Response.Success>(
        'auth/signup',
        {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
            },
        })
}

export const apiActivate = async (activateCode: string) => {
    return await handlerFetch<Api.Response.Success>(
        `auth/activate?activate_code=${activateCode}`
    )
}

export const apiLogin = async (data: Api.Auth.Login) => {
    const formData = new URLSearchParams();
    
    for (const [key, value] of Object.entries(data)) {
		formData.append(key, value);
	}

    return await handlerFetch<Api.Response.Token>(
        'auth/signin',
        {
            method: 'POST',
            headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString()
        }
    )
}

export const apiResetPassword = async (email: string) => {
    const formData = new URLSearchParams();
    
    formData.append('email', email);

    return await handlerFetch<Api.Response.Token>(
        'auth/reset_password',
        {
            method: 'POST',
            headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: email
        }
    )
} 