import { useEffect, useState } from "react";
import { apiGetOne, apiUpdate, apiDelete } from '../../api/crud';
import { Flex, Tabs, Typography, Form, message, Dropdown, Button } from "antd";
import type { TabsProps } from 'antd';
import { useParams, useNavigate } from "react-router-dom";
import { FormatFormInput } from "../../components/FormatFormInput";
import type { MenuProps } from 'antd';
import { DeleteConfirm } from "../../components/modals/DeleteConfirm";
import { BalanceAdd } from "../../components/modals/BalanceAdd";
import { apiCreateTransaction } from "../../api/transaction";

const UserForm = () => {
    const [messageApi, contextHolder] = message.useMessage({ duration: 5 });
    const nav = useNavigate();
    const pageParams = useParams();
    const id = pageParams.id
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm()
    const [data, setData] = useState()
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [balanceModalOpen, setBalanceModalOpen] = useState(false)

    const getData = async () => {
        if (id) {
            const result = await apiGetOne('user', Number.parseInt(id))
            if (result.isError) {
                setLoading(true)
                return;
            }
            setLoading(false)
            return result.data;
        }
    }

    useEffect(() => {
        if (loading) {
            getData().then((data) => {
                setData(data)
                form.setFieldsValue(data)
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [balanceModalOpen])



    const handleSave = async (values: any) => {
        if (id) {
            const result = await apiUpdate('user', parseInt(id), values)
            if (result.isError) {
                messageApi.error('Произошла ошибка при изменении пользователя')
            } else {
                messageApi.success('Изменения успешно сохранены')
            }
        }
    }

    const handleDelete = async () => {
        if (id) {
            const result = await apiDelete('user', parseInt(id))
            if (result.isError) {
                messageApi.error('Произошла ошибка при удалении пользователя')
            } else {
                nav('/admin/user')
            }
        }
    }

    const handleBalance = async (value: number) => {
        if (id) {
            const result = await apiCreateTransaction(parseInt(id), "debit", value)
            if (result.isError) {
                messageApi.error('Произошла ошибка при создании пользователя')
            } else {
                messageApi.success(`Пополнение на ${value} произведено успешно`)
                setLoading(true)
            }
        }
        setBalanceModalOpen(false)
    }

    const onMenuClick: MenuProps['onClick'] = async (e) => {
        switch (e.key) {
            case '1':
                setBalanceModalOpen(true);
                break;
            case '2':
                setDeleteModalOpen(true);
                break;
            case '3':
                nav(-1)
                break;
        }
    };

    const menuItems: MenuProps['items'] = [
        {
            key: '1',
            label: 'Пополнить баланс',
        },
        {
            key: '2',
            label: 'Удалить',
            danger: true,
        },
        {
            type: 'divider'
        },
        {
            key: '3',
            label: 'Отмена'
        }
    ]

    const tabs: TabsProps['items'] = [
        {
            key: '1',
            label: 'Общая информация',
            children: <Form form={form} initialValues={data} labelCol={{ span: 4 }} onFinish={handleSave}>
                <FormatFormInput label='Активен' name='is_active' typeData="boolean" />
                <FormatFormInput label='Электронная почта' name='email' typeData="text" readonly value={Form.useWatch('email', form)} />
                <FormatFormInput label='Фамилия' name='lastname' />
                <FormatFormInput label='Имя' name='firstname' />
                <FormatFormInput label='Роль' name='role' typeData="role" />
                <FormatFormInput label='Номер телефона' name='phone' />
                <FormatFormInput label='Телеграмм' name='telegram' />
                <FormatFormInput label='Баланс' name='balance' typeData='money' readonly value={Form.useWatch('balance', form)} />
            </Form>
        }
    ];

    return <>{contextHolder}
        {!loading &&
            <>
                <DeleteConfirm isOpen={deleteModalOpen} onOk={handleDelete} onCancel={setDeleteModalOpen} />
                <BalanceAdd isOpen={balanceModalOpen} onBalance={handleBalance} onCancel={setBalanceModalOpen} />
                <Flex justify='space-between' align='center' >
                    <Typography.Title level={3} style={{ alignSelf: 'center' }}>Пользователь {id}</Typography.Title>
                </Flex>
                <Tabs defaultActiveKey="1" items={tabs} tabBarExtraContent={
                    <Flex>
                        <Button type="link" onClick={() => nav(-1)}>Назад</Button>
                        <Dropdown.Button menu={{ items: menuItems, onClick: onMenuClick }} onClick={form.submit}>Сохранить</Dropdown.Button>
                    </Flex>
                } />
            </>
        }
    </>

}

export default UserForm
