import { CoreSection } from './Core'
import { Row, Typography, Col, theme } from 'antd'
import { PriceCard } from '../../../components/PriceCard'

const { useToken } = theme

const PricesSection = ({ data }: { data: Api.Response.Plans[] | undefined }) => {
  const { token } = useToken()
  return (
    <CoreSection>
      <Typography.Title level={1}>Цены</Typography.Title>
      <Row
        align='stretch'
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 32 },
          { xs: 8, sm: 16, md: 24, lg: 32 }
        ]}
      >
        {data
          ?.filter(item => item.is_active)
          .sort((a, b) => a.id - b.id)
          .map((item, key) => (
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }} key={item.id}>
              <PriceCard id={item.id} headColor={key === 1 ? token.colorSuccess : token.colorPrimary} />
            </Col>
          ))}
      </Row>
    </CoreSection>
  )
}
export default PricesSection
