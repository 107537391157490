export const helpers = {
  formatMoney(num: number | string): string {
    if (!num) return '0'
    if (typeof num === 'string') {
      num = parseFloat(num)
    }
    if (isNaN(num)) {
      return '0'
    }
    return num.toLocaleString('ru-RU', { style: 'currency', currency: 'RUB' })
  }
}
