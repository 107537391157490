import { Flex, Form, Input, Space, Typography, InputNumber, Radio } from "antd";

const FilterFormUser = ({ form }: { form?: any }) => {
    
  const marginBottomStyle = {
    marginBottom: '0px', // Задайте нужное значение отступа
  };
  // const field = {
  //   "id": 'По id',
  //   "role": "По роли",
  //   "lastname": "По фамилии",
  //   "firstname": "По имени"
  // }
  return (
    <Flex vertical style={{ marginBottom: "20px" }}>
      <Space>
        <Form.Item name="owner__firstname__ilike">
          <Input placeholder="Имя" size="small" />
        </Form.Item>
        <Form.Item name="owner__lastname__ilike">
          <Input placeholder="Фамилия" size="small" />
        </Form.Item>
      </Space>
      <Form.Item name="owner__email__ilike">
        <Input placeholder="Email" size="small" />
      </Form.Item>
      <Form.Item name="owner__telegram__ilike">
        <Input placeholder="Telegram" size="small" />
      </Form.Item>
      <Form.Item name="owner__phone__ilike">
        <Input placeholder="Телефон" size="small" />
      </Form.Item>
      <Typography.Paragraph style={marginBottomStyle}>Баланс пользователя</Typography.Paragraph>
      <Space>
        <Form.Item name="owner__balance__gte" label="От">
          <InputNumber min={0} size="small" />
        </Form.Item>
        <Form.Item name="owner__balance__lte" label="До">
          <InputNumber min={1} size="small" />
        </Form.Item>
      </Space>
      <Form.Item name="owner__is_active">
          <Radio.Group>
            <Radio value=""> Все пользователи </Radio>
            <Radio value="true"> Активные </Radio>
            <Radio value="false"> Не активные </Radio>
          </Radio.Group>
        </Form.Item>
        {/* <Typography.Paragraph style={marginBottomStyle}>Доп. сортировка</Typography.Paragraph>
        <Form.Item name="owner__order_by">
          <Select
            style={{ marginLeft: "5px", width: "150px" }}
            placeholder="Процент скидки"
            size="small"
            options={Object.entries(field).map(([key, value]) => ({
              value: key,
              label: value,
            }))}
          />
        </Form.Item> */}
    </Flex>
  );
};

export default FilterFormUser;
