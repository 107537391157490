import { Modal } from 'antd'

const DeleteConfirm = ({
  isOpen,
  onOk,
  onCancel,
  loading
}: {
  isOpen: boolean
  onOk: any
  onCancel: any
  loading?: boolean
}) => {
  return (
    <Modal
      confirmLoading={loading}
      open={isOpen}
      onOk={onOk}
      onCancel={() => onCancel(false)}
      title='Удаление'
      okText='Да'
      cancelText='Нет'
      okType='danger'
    >
      Вы действительно хотите удалить запись?
    </Modal>
  )
}

export default DeleteConfirm
