import { Card } from 'antd';


type CoreFormType = {
	title: string | React.ReactNode;
	extra?: React.ReactNode;
	children?: React.ReactNode
}

const CoreForm = ({ title, extra, children }: CoreFormType) => {
	return (
		<Card title={title} style={{ margin: '24px 0', overflow: 'initial' }} extra={extra}>
			{children}
		</Card>
	)
}

export default CoreForm;