import { useState } from "react"
import { CoreSection } from "./Core"
import { Button, Form, Input, Typography, Flex } from 'antd'
import React from "react"

const ContactSection = () => {
    const [form] = Form.useForm()
    const [message, setMessage] = useState('')
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const handleSendMessage = async (value: any) => {
        const botToken = "6609384352:AAHniF3A8BtLXoW9OMxwAbYD1sTfAHfqQAE";
        const url = `https://api.telegram.org/bot${botToken}/sendMessage`
        // TODO: убрать параметры в env
        const body = {
            chat_id: "-1002101835770",
            text: `#feedback\nИмя: ${value.name}\nEmail: ${value.email}\nTG: ${value.telegram}\nСообщение: ${value.message}`
        }
        await fetch(url, {
            method: 'POST', 
            body: JSON.stringify(body), 
            headers: {
                "Content-Type": "application/json",
            },
        }).then(data => setMessage('Спасибо за сообщение. Мы скоро свяжемся с вами')).catch((error) => setMessage('Произошла ошибка. Попробуйте повторить позднее'));

    }

    return <CoreSection>
        <Form {...layout} form={form} onFinish={handleSendMessage} >
            <Typography.Title level={1}> Форма обратной связи</Typography.Title>
            <Form.Item label='Имя' name='name' rules={[{ required: true }]} >
                <Input />
            </Form.Item>
            <Form.Item label='Telegram' name='telegram'  >
                <Input />
            </Form.Item>
            <Form.Item label='Email' name='email' >
                <Input />
            </Form.Item>
            <Form.Item label='Текст сообщения' name='message' >
                <Input.TextArea size="large" />
            </Form.Item>
            <Flex justify="end">
                <Typography.Text>{message}</Typography.Text>
                <Button type='primary' htmlType="submit">Отправить</Button>
                {/* TODO: добавить скрипт передачи сообщение в телеграмм */}
            </Flex>
        </Form>
    </CoreSection>
}

export default ContactSection