import React from 'react'
import { apiGetOne } from '../../api/crud'
import { FormatField } from '../FormatField'

const TranslationStatus = ({ id }: { id: number }) => {
  console.log(id)
  const abortControllerStatus = new AbortController()
  const [status, setStatus] = React.useState<string>('')
  const getStatus = async () => {
    try {
      const req = await apiGetOne(`translate/${id}/status/`, undefined, abortControllerStatus.signal)
      if (req.isError) {
        throw new Error(req.data.detail.toString())
      }
      setStatus(req.data.status)
    } catch (err) {
      console.error(err)
    }
  }
  React.useEffect(() => {
    getStatus()
  }, [])
  return <FormatField value={status} type='status' />
}

export default TranslationStatus
