import React from "react";
import { Flex, theme } from "antd";
import { CheckSquareOutlined, CloseSquareOutlined } from "@ant-design/icons";

const { useToken } = theme;

interface PriceOptionProps {
  label: string;
  value: boolean | string | number | undefined;
}

const PriceOption: React.FC<PriceOptionProps> = ({ label, value }) => {
  const { token } = useToken();

  const renderIcon = (value: boolean) =>
    value ? (
      <CheckSquareOutlined
        style={{ color: token.colorSuccess, fontSize: "1.2rem" }}
      />
    ) : (
      <CloseSquareOutlined
        style={{ color: token.colorError, fontSize: "1.2rem" }}
      />
    );

  const renderValue = () => {
    if (typeof value === "boolean") {
      return (
        <>
          {renderIcon(value)}
          <div style={{ fontSize: "0.8rem" }}>{label}</div>
        </>
      );
    } else if (typeof value === "string") {
      return (
        <>
          {renderIcon(true)}
          <div style={{ fontSize: "0.8rem" }}>{`${label} ${value}`}</div>
        </>
      );
    } else if (typeof value === "number") {
      return (
        <>
          {/* Грабли для поля "приоритет перевода", если там прилетел ноль значит приотета нет */}
          {renderIcon(value !== 0 ? true : false)}
          <div style={{ fontSize: "0.8rem" }}>{`${label} ${value}`}</div>
        </>
      );
    }

    return null;
  };

  return (
    <Flex
      align="normal"
      justify="start"
      gap={8}
      style={{ marginBottom: "8px" }}
    >
      {renderValue()}
    </Flex>
  );
};

export default PriceOption;
